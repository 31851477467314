<form [formGroup]="filterForm" class="filter" style="display: contents">
  <mat-form-field>
    <select-infinite-scroll-search
      #selectInfinite
      formControlName="filter"
      [internalFilter]="true"
      [options]="options"
      [labelShow]="'friendly'"
      [multiple]="true"
      [isTableFilter]="true"
      (filterEvent)="handleFilterChange($event)"
      (clearFilter)="clearFilter()"
      placeholder="Buscar...">
    </select-infinite-scroll-search>
  </mat-form-field>
</form>
