import {
  AfterViewInit,
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  HostListener,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, DecimalPipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

import { DynamicTableDataSource } from './dynamic-table-datasource';
import { DynamicTable } from '../../../../../utils/models/table.interface';
import { RequestAttribute } from '../../../../../utils/models/http.interface';
import { PageSelectComponent } from '../../common/page-select/page-select.component';

import { HttpService } from './service/httpService.service';

import { animations } from '../../../../../utils/animations';

import moment from 'moment';

const NUMBER_ITENS_DEFAULT = 10;
@Component({
  selector: 'dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  animations
})
export class DynamicTableComponent
  implements AfterViewInit, OnInit, OnChanges, OnDestroy {

  private readonly _unsubscribe$: Subject<any> = new Subject();

  @ViewChildren('filterTable') filterTables;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild('pageSelect', { static: true }) pageSelect: PageSelectComponent;
  @ViewChildren('checkBox') checkBox: QueryList<any>;

  @Input() dynamicTable: DynamicTable;
  @Input() attributes: RequestAttribute[];
  @Input() manyItens = NUMBER_ITENS_DEFAULT;
  @Input() hasPaginate = true;
  @Input() reload;

  // Output Action
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() duplicate: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() view: EventEmitter<any> = new EventEmitter();
  @Output() tableRowsEdited: EventEmitter<any> = new EventEmitter();

  // Output Uteis
  @Output() loadedTable: EventEmitter<any> = new EventEmitter();
  @Output() filter: EventEmitter<any> = new EventEmitter();

  dataSource: DynamicTableDataSource;

  _dynamicTable: DynamicTable;
  attributeParams: RequestAttribute[] = [];
  filterParams: RequestAttribute[] = [];

  data: any[] = [];
  editRow: boolean[] = [];
  checked = [];
  checks = new Array(NUMBER_ITENS_DEFAULT).fill(false);

  filterOptions = {};

  dateFormat = 'DD/MM/YYYY';
  dateHourFormat = 'DD/MM/YYYY - HH:mm';
  flagFilter = true;
  width;
  dateForm: FormGroup;
  flagEditAll: boolean;

  constructor(
    private readonly decimalPipe: DecimalPipe,
    private readonly datePipe: DatePipe,
    private readonly formBuilder: FormBuilder,
    public dialog: MatDialog,
    public httpService: HttpService
  ) {
    this.dateForm = this.formBuilder.group({
      date: [null],
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.width = event.target.innerWidth;
    this.reloadSticky();
  }

  ngOnInit(): void {
    this.dataSource = new DynamicTableDataSource(this.httpService, this.dialog);

    if (this.attributes !== undefined) {
      this.attributeParams = this.attributes;
    }
    this._dynamicTable.requestParams.limit = this.manyItens;
    this.dataSource.loadRequest(
      this._dynamicTable.endpoint,
      this._dynamicTable.requestParams,
      this.attributeParams,
      this.filterParams
    );

    const hasSomeFilter = this._dynamicTable.displayedColumns.some(column => column.hasFilter === true);
    if (hasSomeFilter) {
      this.dataSource.loadFilters(
        this._dynamicTable.endpoint,
        this._dynamicTable.requestParams,
        this.attributeParams,
        this._dynamicTable.endpointFilter
      );
    }
  }

  ngAfterViewInit() {
    this.width = window.innerWidth;

    this.dataSource.filters$.subscribe((data) => {
      this.setFilterOptions(data[0]);
    });

    this.dataSource.data$.subscribe((data: any) => {
      data.forEach((item, index) => {
        if (this.checked.includes(item.id)) {
          this.checks[index] = true;
        } else {
          this.checks[index] = false;
        }
      });

      if (data.length) {
        let aux = '';
        data.forEach((e) => {
          if (e.favoriteMachines) {
            e.favoriteMachines.forEach((f) => {
              aux += f + ',';
            });
            e.favoriteMachines = aux.replace(/,\s*$/, ' ');
            aux = '';
          }
        });

        if (data[0]?.dates) {
          this._dynamicTable = JSON.parse(JSON.stringify(this.dynamicTable));
          data.forEach((e, index) => {
            data[index].dates.forEach((element, i) => {
              e[`dates${i}`] = element.value;
            });
          });

          const dynamicAux = this._dynamicTable.columns.pop();
          data[0].dates.forEach((e, i) => {
            this._dynamicTable.columns.push(`dates${i}`);
            this._dynamicTable.displayedColumns.push({
              attribute: `dates${i}`,
              title: e.date,
              type: 'number',
              sticky: false,
              subtitle: {
                name: '',
                look: true,
              },
            });
          });
          this._dynamicTable.columns.push(dynamicAux);
        }
      }
      this.data = data;

      this.loadedTable.emit(data);
      this.reloadSticky();
      this.editRow = new Array(this.data.length).fill(false);
    });
  }

  ngOnChanges(changes): void {
    if (this.dynamicTable && changes.dynamicTable) {
      this._dynamicTable = JSON.parse(JSON.stringify(this.dynamicTable));
    }
    if(changes.reload && this.reload){
      this.handleFilter([],'')
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next('');
    this._unsubscribe$.complete();
  }

  changePage(event): void {
    this._dynamicTable.requestParams.page = event;
    this.loadTable();
  }

  changeManyItens(event): void {
    this._dynamicTable.requestParams.limit = event;
    this._dynamicTable.requestParams.page = 1;
    this.loadTable();
  }

  trackArray(index): number {
    return index;
  }

  async loadTable(reloadFilter = false) {
    if (reloadFilter) {
      this.dataSource.loadFilters(this._dynamicTable.endpoint, this._dynamicTable.requestParams, this.attributeParams);
    }
    this.dataSource.loadRequest(this._dynamicTable.endpoint, this._dynamicTable.requestParams, this.attributeParams, this.filterParams);
  }

  async reloadSticky() {
    await this.sleep(1);
    this.table.updateStickyColumnStyles();
  }

  sortFunctionData(a, b) {
    let lenghtSort = 0;
    const typeData = typeof a[this._dynamicTable.requestParams.sort];

    if (typeData === 'undefined') {
      lenghtSort = (a[this._dynamicTable.requestParams.sort] === undefined && b[this._dynamicTable.requestParams.sort] !== undefined) ? 1 : -1;
    } else if (typeData === 'object') {
      const strA = a[this._dynamicTable.requestParams.sort].toString().toLowerCase();
      const strB = b[this._dynamicTable.requestParams.sort].toString().toLowerCase();
      lenghtSort = this.validObjectTypeSort(strA, strB);
    } else if (this.validAllDate(a[this._dynamicTable.requestParams.sort], b[this._dynamicTable.requestParams.sort])) {
      const dates = this.attributeDateSort(a[this._dynamicTable.requestParams.sort], b[this._dynamicTable.requestParams.sort]);
      lenghtSort = this.validLenghtSort(dates.firstDate, dates.secondDate);
    } else if (!isNaN(parseFloat(a[this._dynamicTable.requestParams.sort]))) {
      const auxA = parseFloat(a[this._dynamicTable.requestParams.sort]);
      const auxB = parseFloat(b[this._dynamicTable.requestParams.sort]);
      lenghtSort = this.validLenghtSort(auxA, auxB);
    } else if (typeData === 'number') {
      lenghtSort = this.validLenghtSort(a[this._dynamicTable.requestParams.sort], b[this._dynamicTable.requestParams.sort]);
    } else if (typeData === 'string') {
      const strA = a[this._dynamicTable.requestParams.sort].toString().toLowerCase();
      const strB = b[this._dynamicTable.requestParams.sort].toString().toLowerCase();

      lenghtSort = this.validLenghtSort(strA, strB);
    }

    return lenghtSort;
  }

  validLenghtSort(a, b) {
    return (a > b) ? 1 : -1;
  }

  validObjectTypeSort(objectA, objectB) {
    if (objectA === '' && objectB !== '') {
      return 1;
    } else if (objectA !== '' && objectB === '') {
      return -1;
    }

    return this.validLenghtSort(objectA, objectB);
  }

  validDateSort(date, format) {
    return moment(date, format, true).isValid();
  }

  validAllDate(dateA, dateB) {
    return (this.validDateSort(dateA, this.dateFormat) && this.validDateSort(dateB, this.dateFormat)) || 
      (this.validDateSort(dateA, this.dateHourFormat) && this.validDateSort(dateB, this.dateHourFormat));
  }

  attributeDateSort(firstDate, secondDate) {
    let dateA;
    let dateB;
    if (this.validDateSort(firstDate, this.dateFormat)) {
      dateA = moment(firstDate, this.dateFormat, true);
      dateB = moment(secondDate, this.dateFormat,true);
    } else {
      dateA = moment(firstDate, this.dateHourFormat, true);
      dateB = moment(secondDate, this.dateHourFormat, true);
    }

    return {'firstDate': dateA, 'secondDate': dateB };
  }


  handleSort(att: string): void {
    if (this._dynamicTable.requestParams.sort === att) {
      if (this._dynamicTable.requestParams.order === 'desc') {
        this._dynamicTable.requestParams.order = 'asc';
        this._dynamicTable.requestParams.sort = att;
      } else if (this._dynamicTable.requestParams.order === 'asc') {
        this._dynamicTable.requestParams.order = 'desc';
        this._dynamicTable.requestParams.sort = att;
      }
    } else {
      this._dynamicTable.requestParams.order = 'asc';
      this._dynamicTable.requestParams.sort = att;
    }
    this.data.sort((a, b) => this.sortFunctionData(a, b));

    if (this._dynamicTable.requestParams.order === 'desc') {
      this.data.reverse();
    }

    this.dataSource.loadRequestOrder(this.data)
  }

  emptyText(e: any) {
    return e ? e : '-';
  }

  setFilterOptions(options: any) {
    this._dynamicTable.displayedColumns.forEach((column) => {
      this.filterOptions[column.attribute] = [];
    });
    if(options) {
      Object.keys(options).forEach((key) => {
        if (options[key]) {
          const aux = this._dynamicTable.displayedColumns.find(
            (item) => item.attribute === key
          );
          if (aux) {
            const tipo = aux.type;
            options[key].forEach((element) => {
             this.updateFilterElements(key, element, tipo);
            });
          }
        }
      });
    }
  }

  updateFilterElements(key, element, tipo) {
    if (element === null) {
      this.filterElement(key, element, ' ');
    } else if (tipo === 'number') {
      this.filterElement(key, element, this.decimalPipe.transform(element, '1.0-2'));
    } else if (tipo === 'text' || tipo === 'array') {
      this.filterElement(key, element, element);
    } else if (tipo === 'date') {
      this.filterElement(key, element, this.datePipe.transform(element, 'dd/MM/yyyy'));
    } else if (tipo === 'date-time') {
      this.filterElement(key, element, this.datePipe.transform(element,"dd/MM/yyyy 'às' HH:mm"));
    } else if (tipo === 'bool') {
      this.filterElement(key, element, this.elementBoolValid(element));
    }
  }

  elementBoolValid(element) {
    return element ? 'Sim' : 'Não';
  }

  filterElement(key, valueElement, friendlyElement) {
    this.filterOptions[key].push({
      value: valueElement,
      friendly: friendlyElement,
    });
  }

  handleFilter(filters: any, att: string) {
    const index = this.filterParams.findIndex(item => item.param === att);

    if (index > -1) {
      this.filterParams.splice(index, 1);
    }

    if (filters.filter.length > 0) {
      if (['activities', 'routes', 'carbonContent','time'].includes(att)) {
        this.filterParams.push({
          param: att,
          value: filters.filter.map(item => item.value).join(',')

        });
      } else {
        this.filterParams.push({
          param: att,
          value: att === 'user' ? filters.filter.map(item => item.value._id.replace(/,/g, '.')).join(',') : filters.filter.map(item => item.value.replace(/,/g, '.')).join(',')
        });
      }

    }

    this.flagEditAll = this.filterParams.length > 0 ? true : false
    this.filter.emit(this.filterParams);
    this._dynamicTable.requestParams.page = 1;
    this.loadTable();
  }

  handlePin(column: any) {
    if (!column.sticky) {
      column['sticky'] = true;
    } else {
      column.sticky = !column.sticky;
    }
  }

  handleEdit(event: any): void {
    this.editRow = new Array(this.data.length).fill(false);
    this.tableRowsEdited.emit(this.editRow);
    this.edit.emit(event);
  }

  handleDuplicate(event: any): void {
    this.duplicate.emit(event);
  }

  handleDelete(event: any): void {
    this.delete.emit(event);
  }

  handleView(event: any): void {
    this.view.emit(event);
  }

  setEdit(indexRow) {
    this.editRow[indexRow] = true;
    this.tableRowsEdited.emit(this.editRow);
  }

  setRowCol(row, col) {
    let input = document.getElementById(`[${row + 1}][${col}]`);
    if (!input) {
      input = document.getElementById(`[${0}][${col}]`);
    }
    input.focus();
  }

  decimalFilter(event: any) {
    const reg = /^\d*(?:[.,]\d{0,2})?$/;
    const input = event.target.value + String.fromCharCode(event.charCode);
    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  changeDate(event: any) {
    this.flagFilter = event ? false : true;
  }
}
