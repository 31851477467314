<mat-form-field [floatLabel]="'always'" appearance="outline" class="custom-spinner-number-input">
    <mat-label class="mat-label-date">{{ numberInput.label }}</mat-label>
    <div
        [ngClass]="{
            'input-container': numberInput.maximo <= 9999,
            'input-container-million': numberInput.maximo > 9999 && numberInput.maximo <= 999999,
            'input-container-billion': numberInput.maximo > 999999 && numberInput.maximo <= 99999999,
            'input-container-trillion': numberInput.maximo > 99999999,
            'disabled': numberInput.disabled
        }"
        >
        <button
        [disabled]="numberInput.disabled"
        class="circle-button-spinner-left"
        (click)="downValue($event)"
        style="background-image: url('../../../../../../assets/icons/styledIcons/Minus.svg');"
          >
          </button>
          <input
            matInput
            styledIcons="text-align: center;"
            [placeholder]="numberInput.placeholder"
            type="number"
            [(ngModel)]="numberInput.value"
            [disabled]="numberInput.disabled"
            [min]="numberInput.minimo"
            [max]="numberInput.maximo"
            [step]="numberInput.step"
            (change)="setNewValue()"
            (focus)="canShowSpinner()"
            >
        <button
          [disabled]="numberInput.disabled"
          class="circle-button-spinner-right"
          (click)="upValue($event)"
          style="background-image: url('../../../../../../assets/icons/styledIcons/Plus.svg');"
        >
          </button>
        </div>
</mat-form-field>
