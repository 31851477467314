import { Component, Input } from '@angular/core';
import { AvatarInterface } from '../../../../../utils/models/miscelanea.interface';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {

  @Input() avatar: AvatarInterface;

  get nameAvatar() {
    let name = '';
    this.avatar.name.split(' ').forEach((nm, idx) => {
      if (idx <= 1) {
        name += nm.substring(0, 1);
      }
    })
    return name;
  }

}
