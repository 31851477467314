import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { EventDispatcherService } from 'src/app/internal/services/eventDispatcher.service';
import { InternalService } from 'src/app/internal/services/internal.service';
import { ModalLoadingComponent } from '../../loads/modal-loading/modal-loading.component';
const DATE_FORMAT = 'DD/MM/AAAA';

@Component({
  selector: 'app-modal-finish-manual-supervision',
  templateUrl: './modal-finish-manual-supervision.component.html',
  styleUrls: ['./modal-finish-manual-supervision.component.scss']
})
export class ModalFinishManualSupervisionComponent implements OnInit {

  form = this.formBuilder.group({
    timeValue: new FormControl(null, Validators.required),
    intervalValue: new FormControl(null, []),
    datapickerValue: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
  });

  timerInterface =  { disabled: false, value: this.form.value.timeValue, label: 'Hora fim', placeholder: 'HH:MM' }
  datapickerInterface =  { disabled: false, date: this.form.value.datapickerValue, label: 'Data fim', placeholder: DATE_FORMAT }
  checkBoxInterface = { disabled: false, label: "Inserir histórico de mensagens no whatsapp", checked:false}
  checkBoxInterfaceEmail = { disabled: false, label: "Enviar dados da ocorrência por e-mail", checked:false}
  textInput =  { value: this.form.value.description, label: 'Informações',  placeholder: 'Ocorrência finalizada' };
  textAreaInterface =  { value: this.form.value.description, label: 'Informações', rows: 15, placeholder: 'Ocorrência finalizada',  hasEditableIcon: true };


  constructor(    
    public dialogRef: MatDialogRef<ModalFinishManualSupervisionComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    private readonly internalService: InternalService,
    private readonly toastr: ToastrService,
    private readonly eventDispatcher: EventDispatcherService,
    public dialog: MatDialog,
    ) { }

  ngOnInit(): void {
  }

  onCheckChange(event){
    this.checkBoxInterface.checked = event;
  }

  onCheckEmail(event){
    this.checkBoxInterfaceEmail.checked = event;
  }

  onTogglesCalc(event){
    if(event.checked){
      const date = new Date(this.form.value.datapickerValue)
      date.setHours(this.form.value.timeValue.substr(0,2),this.form.value.timeValue.substr(3));
      this.form.get('intervalValue').setValue(this.internalService.calculateInterval(new Date(this.data.start),date));
    }
  }

  handleClick(type): void {

    if(!type) this.dialogRef.close(type);
    else{

      const dialog = this.dialog.open(ModalLoadingComponent, {
        width: '400px',
        disableClose: true,
        data: {
          title: 'Carregando',
          text: 'Por favor espere alguns segundos',
        },
      });

      const date = new Date(this.form.value.datapickerValue)
      date.setHours(this.form.value.timeValue.substr(0,2),this.form.value.timeValue.substr(3));
      const sendObj = {
        finishDate: date,
        information: this.form.value.description,
        sendGroupHistory: this.checkBoxInterface.checked,
        length: this.internalService.intervalToMilisseconds(this.form.value.intervalValue)
      }
       this.internalService.endSupervision(this.data.id,sendObj).subscribe(resp=>{
        this.toastr.show('Supervisão finalizada com sucesso', 'Sucesso', { timeOut: 500 , messageClass: 'success'});
        this.eventDispatcher.emitReloadSupervision(true)
        dialog.close();
        this.dialogRef.close(true);
      },error => {
        dialog.close();
        this.toastr.show('Algo de errado aconteceu, tente novamente.', 'Erro', { timeOut: 5000 , messageClass: 'error'});
      })  
    }
  }

  onValueEmitterTextAreaInput(newValue,field){
    this.form.get(field).setValue(newValue)
  }

  onValueDate(event){
    this.form.get('datapickerValue').setValue(new Date(event))
  }

  onValueTime(event){
    //this.form.get('timeValue').setValue(event)
  }

}
