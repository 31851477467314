import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import 'chartjs-chart-matrix';

@Component({
  selector: 'chart',
  templateUrl: './chart.component.html'
})

export class ChartComponent implements OnInit {
  @ViewChild('graphicChart', { static: true }) readonly graphicChart: any;

  @Input() typeGraphic: any = 'line';
  @Input() datasets: any = [];
  @Input() options: any = {};
  @Input() label: any = [];

  chartMod: any;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    Chart.register(...registerables);

    if (this.datasets.length > 0) {
      this.loadChart();
    }
  }

  loadChart() {
    if (this.chartMod instanceof Chart) {
      this.chartMod.destroy();
    }

    this.chartMod = new Chart(this.graphicChart.nativeElement, {
      type: this.typeGraphic,
      data: {
        labels: this.label,
        datasets: this.datasets,
      },
      options: this.options,
    });
  }
}
