import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { InternalService } from "src/app/internal/services/internal.service";
import { ModalValidateEventComponent } from "src/app/shared/components/dialogs/modal-validate-event/modal-validate-event.component";
import { RequestAttribute, RequestParams } from "../models/http.interface";
import { EventDispatcherService } from "src/app/internal/services/eventDispatcher.service";
const TIME_OUT = 240000;
@Injectable()


export class SenderAlertMessages {
    requestParams: RequestParams = {
        sort: 'createdAt',
        order: 'desc',
        page:1,
        limit: 10,
        skip:0
    };
    requestAttributesEventGroups: RequestAttribute[]=[]
    requestAttributes: RequestAttribute[] = [
        {param: "priority", value: "true"},
    ];

    openToasts = []

    constructor(public readonly toastr: ToastrService, 
        public dialog: MatDialog, 
        private readonly internalService: InternalService,
        private readonly eventDispatcher: EventDispatcherService) { 

            this.eventDispatcher.updateParamsEventGroup.subscribe((update)=>{
                if(update){
                  this.requestAttributesEventGroups = update.requestAttributes 
                  this.requestParams = update.requestParams;
                }
               })

        }

    getMessages(){
        setInterval(()=>{
            if(!this.openToasts.length){
                this.internalService.fetchData(this.requestParams,this.requestAttributesEventGroups).subscribe((response:any)=>{
                    this.eventDispatcher.emitUpdateListEventGroup(response.groupData);
                    this.eventDispatcher.emitUpdateListSupervision(response.supervisionData);
                    this.eventDispatcher.emitNotifyNewOccurrences({occurrences: response.lastOccurrenceId });
                    this.eventDispatcher.emitNotifyNewSupervision({supervision: response.lastSupervisionId });

                    if(response.notifications.length){
                        this.openToasts = [];
                        response.notifications.forEach(element => {
                            this.openToasts.push(this.sendMessage(element));
                        });
                        this.playAudio();
                        this.eventDispatcher.emitHasNewOccurrences(true);
                    }
                    if(response.groupData && response.groupData.length){
                        this.eventDispatcher.emitUpdateListEventGroup(response.groupData)
                    } 
                })
            }
        },30000)
    }

    sendMessage(newOccurrence){
        const toast = this.toastr.show( 'Teste Basico','Ocorrência', {  
            disableTimeOut:true, 
            messageClass: 'basic',
            closeButton: true,
            tapToDismiss: false,
            timeOut: 50000,
            extendedTimeOut: 50000,
        })

        toast.onHidden.subscribe(hidden =>{
            this.openToasts = this.openToasts.filter(el => el.toastId !== toast.toastId)
        })
        
        toast.onAction.subscribe(x => {
            this.openToasts.forEach(el=>{
                this.toastr.clear(el.toastId);
            })
            this.dialog.open(ModalValidateEventComponent, {
                disableClose: true,
                width: '460px',
                height: '83%',
                panelClass: 'custom-modalbox',
                //maxHeight: '941px',
                data: {
                  type: 'success',
                  cancelButton: {text: 'Recusar'},
                  validationButton: {text: 'VALIDAR'},
                  sendData: newOccurrence,
                  statusGroup: 'Nova'
                }
            })
        });

        console.log("Mensagem enviada")
        const eventDate = new Date(newOccurrence.date)
        toast.toastRef.componentInstance.data = {
            id: `ID ${newOccurrence.idSeq ? newOccurrence.idSeq : newOccurrence.data.id ? newOccurrence.data.id : ''}`,
            station: newOccurrence.translatedSubstation,// "Usina Paulo Afonso IV/ P. AFONSO IV",
            equipment: newOccurrence.equipment,
            description: `Há um novo ${newOccurrence.type ? newOccurrence.type.toLowerCase() : ''} na ${newOccurrence.translatedSubstation} ${newOccurrence.equipment} adicionado.`,//"Há um novo desarme Usina Paulo Afonso IV/ P. AFONSO IV 05G6 acionado.",
            dateString: eventDate.getDate() == new Date().getDate() ? `Hoje ${eventDate.toLocaleTimeString("pt-BR",{hour: '2-digit', minute:'2-digit'})}` : `${eventDate.toLocaleDateString()} às  ${eventDate.toLocaleTimeString("pt-BR",{hour: '2-digit', minute:'2-digit'})}`
        };
        return toast;
    }

    playAudio(){
        let audio = new Audio();
        audio.src = "../../assets/audio/alert.wav";
        //audio.muted = false;
        audio.load();
        audio.play();
    }

    getLastMessages(){
        return new Promise<boolean>((resolve) => {
        this.internalService.getNewOccurrences(this.requestParams,this.requestAttributes).subscribe((response:any)=>{
            if(response.rows.length){
                this.openToasts = [];
                response.rows.forEach(element => {
                    this.openToasts.push(this.sendMessage(element));
                });
                resolve(true)
            }
        },error=>{
            if(!error.error.rows.length){
                resolve(false)
                this.toastr.show('Não há nenhuma nova ocorrência para ser exibida.', 'Não há novas ocorrências', { timeOut: TIME_OUT, messageClass: 'error'});
            }
        })
        })

    }

    hasNewOccurrences(){
        return new Promise<boolean>((resolve) => {
            this.internalService.getNewOccurrences(this.requestParams,this.requestAttributes).subscribe((response:any)=>{
                if(response.rows.length){
                    resolve(true)
                } else resolve(false)
            },error=>{
                if(!error.error.rows.length){
                    resolve(false)
            }})
        })

    }

}