<div class="modal-container">
    <div class="head">
      <div class="title">
        Finalizar ocorrência
      </div>
      <mat-icon class="icon" (click)="handleClick(false)">close</mat-icon>
    </div>
    <div class="line"></div>

    <div class="content">
        <div class="text">
            Olá usuário! Para finalizar a ocorrência informe os dados abaixo:
        </div>
    </div>

    <form [formGroup]="form" class="data-inputs">
            
        <div class="time-inputs">
            <datapicker-input [datapicker]="datapickerInterface" (valueEmitter)="onValueDate($event)" [disabled]="true" formControlName="datapickerValue"></datapicker-input>

            <mat-form-field [floatLabel]="'always'" appearance="outline">
                <mat-label>Hora fim</mat-label>
                <input type="time" (input)="onValueTime($event)" formControlName="timeValue" placeholder="10:00h" matInput>
            </mat-form-field>
                 
        </div>
        
        <div class="text-infos">
<!--             <text-input [textInput]="textInput" (valueEmitter)="onValueEmitterTextAreaInput($event, 'description')"></text-input>
 -->            <textarea-input [textAreaInput]="textAreaInterface" (valueEmitter)="onValueEmitterTextAreaInput($event, 'description')"></textarea-input>

        </div>

        <div class="line" style="margin-top: 10px; width: 100%;"></div>

        <div class="subtitle">
            AÇÕES EXTRAS <span class="label-obs">(Opcional) </span>
        </div>

        <div class="checkbox">
            <checkbox [checkbox]="checkBoxInterface" (onChange)="onCheckChange($event)"></checkbox>
<!--             <checkbox [checkbox]="checkBoxInterfaceEmail" (onChange)="onCheckEmail($event)"></checkbox>
 -->        </div>

    </form>
    <div class="line no-margin-top" style="margin-top: 10px;margin-bottom: 0px;"></div>

    <div class="action">
        <button-induxtry *ngIf="data?.cancelButton" (action)="handleClick(false)" 
            [buttonInduxtry]="{ label: data?.cancelButton?.text, customStyle: 'cancel' }">
        </button-induxtry >
        <button-induxtry *ngIf="data?.validationButton" (action)="handleClick(true)" 
            [buttonInduxtry]="{ label: data?.validationButton?.text, showLabel:true, disabled: !this.form.valid, customStyle: 'primary'}">
        </button-induxtry>
      </div>
</div>