<calendar *ngIf="_showRegularDatepicker"
    [formControl]="_regularPickerCtrl"
    [id]="id"
    [customFormFieldClass]="calendarPicker.customClass"
    [label]="calendarPicker.label"
    [max]="calendarPicker.max"
    [min]="calendarPicker.min"
    [mode]="calendarPicker.mode"
    [touchUi]="calendarPicker.touchUi"
    [showTime]="showTime">
</calendar>

<calendar-month *ngIf="_showMonthPicker"
    [formControl]="_monthPickerCtrl"
    [customFormFieldClass]="calendarPicker.customClass"
    [label]="calendarPicker.label"
    [calendarPicker.]="calendarPicker.max"
    [min]="calendarPicker.min"
    [mode]="calendarPicker.mode"
    [touchUi]="calendarPicker.touchUi">
</calendar-month>

<calendar-year *ngIf="calendarPicker.mode === 'ANO'"
                 [formControl]="_yearPickerCtrl"
                 [customFormFieldClass]="customClass"
                 [label]="calendarPicker.label"
                 [max]="calendarPicker.max"
                 [min]="calendarPicker.min"
                 [touchUi]="calendarPicker.touchUi">
</calendar-year>

<calendar-period *ngIf="_showPeriodPicker"
    [label]="calendarPicker.label"
    [formControl]="_periodPickerCtrl"
    [customFormFieldClass]="calendarPicker.customClass"
    [max]="calendarPicker.max"
    [min]="calendarPicker.min"
    [mode]="calendarPicker.mode"
    [touchUi]="calendarPicker.touchUi">
</calendar-period>