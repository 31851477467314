<div class="modal-container">
    <div class="head">
      <div class="title">
        Validar ocorrência
      </div>
      <mat-icon class="icon" (click)="handleClick(false)">close</mat-icon>
    </div>
    <div class="line"></div>

    <div class="content">
        <div class="text">
            Olá usuário! Para validar a ocorrência verifique os dados abaixo:
        </div>
        <div class="subtitle">
            INFORMAÇÕES DA OCORRÊNCIA
        </div>

        <div class="title-infos">
            <span class="id-occurrence">ID{{occurrence.idSeq ? occurrence.idSeq : occurrence.data.id}}</span>
            <mat-divider class="vertical-divider" [vertical]="true"></mat-divider>
            <span class="name-occurrence">{{occurrence.translatedSubstation}} {{occurrence.equipment}}</span>
            <tags [tag]="getTagColors(data.statusGroup)"></tags>

        </div>

        <div class="lt" *ngIf="occurrence.highRisk"><mat-icon *ngIf="true" class="icon-lt" svgIcon="alert-yellon"></mat-icon><span>LT DE ALTO RISCO</span></div>


        <form [formGroup]="form" class="data-inputs">
            
            <div class="time-inputs">
                <datapicker-input [datapicker]="datapickerInterface" [disabled]="true" formControlName="datapickerValue"></datapicker-input>
                <time-input [timerInput]="timerInterface"></time-input>
            </div>

            <div>
                <text-input [textInput]="textInterface"></text-input>
            </div>

            <div class="line"></div>
            
            <div class="text-infos">
                <textarea-input *ngIf="occurrence.sapText" [textAreaInput]="textAreaInterface[3]" (valueEmitter)="onValueEmitterTextAreaInput($event, 'sap')"></textarea-input>
                <textarea-input *ngIf="occurrence.weatherText" [textAreaInput]="textAreaInterface[4]" (valueEmitter)="onValueEmitterTextAreaInput($event, 'sap')"></textarea-input>
                <textarea-input [textAreaInput]="textAreaInterface[0]" (valueEmitter)="onValueEmitterTextAreaInput($event, 'description')"></textarea-input>
                <textarea-input [textAreaInput]="textAreaInterface[1]" (valueEmitter)="onValueEmitterTextAreaInput($event, 'breakers')"></textarea-input>
                <textarea-input style="margin-bottom: 0px;" [textAreaInput]="textAreaInterface[2]" (valueEmitter)="onValueEmitterTextAreaInput($event, 'trips')"></textarea-input>
            </div>

            <div class="line no-margin-top"></div>
 <!-- 
            <div class="subtitle">
                AÇÕES EXTRAS <span class="label-obs">(Opcional) </span>
            </div>

            <checkbox [checkbox]="checkBoxInterface[1]" (onChange)="onCheckChange($event,1)"></checkbox>
 -->
        </form>
    </div>

    <div class="action">
      <button-induxtry *ngIf="data?.cancelButton" (action)="confirmReject('Rejeitada')" 
          [buttonInduxtry]="{ label: data?.cancelButton?.text, customStyle: 'cancel', showLabel:true, disabled: data.disableCancel }">
      </button-induxtry >
      <button-induxtry *ngIf="data?.validationButton" (action)="handleClick('Aprovado')" 
          [buttonInduxtry]="{ label: data?.validationButton?.text, customStyle: 'primary', showLabel:true, disabled: data.disableAprove }">
      </button-induxtry>
    </div>
  </div>
  