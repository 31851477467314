import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { EventDispatcherService } from 'src/app/internal/services/eventDispatcher.service';
import { InternalService } from 'src/app/internal/services/internal.service';
import { NumberSuffixInput, TextInput } from 'src/utils/models/input.interface';
import { SwalComponent } from '../swal/swal.component';
import { ModalLoadingComponent } from '../../loads/modal-loading/modal-loading.component';
const DATE_FORMAT = 'DD/MM/YYYY';

@Component({
  selector: 'app-modal-validate-event',
  templateUrl: './modal-create-manual-occurrence.component.html',
  styleUrls: ['./modal-create-manual-occurrence.component.scss']
})
export class ModalCreateManualOccurrenceComponent implements OnInit {

  typeButton = [ 
    {
      type: 'error', name: 'Error' 
    },
    {
      type: 'success', name: 'Success' 
    },
    {
      type: 'alert', name: 'Alert' 
    },
    {
      type: '', name: 'Default' 
    }
  ];
  
  FINISH_COLOR =  { background: "#EDFDF4", content: '#17B55F' }
  WAITING_COLOR = { background: "#E0E9F6", content: "#102956"}
  CANCELED_COLOR = { background: "#FFD9D6", content: "#660700"}  
  NEW_COLOR = { background: "#FDF1D8", content: "#5F4407"}
  ARRAY_COLORS = {"Finalizado": this.FINISH_COLOR, "Finalizada": this.FINISH_COLOR, "Nova": this.NEW_COLOR, "Cancelada": this.CANCELED_COLOR, "Em andamento": this.WAITING_COLOR}
  optionsCod = [];


  form = this.formBuilder.group({
    timeValue: new FormControl('',[Validators.required]),
    datapickerValue: new FormControl('',[Validators.required]),
    description: new FormControl(''),
    cod: new FormControl('',[Validators.required]),
    breakers: new FormControl(''),
    trips: new FormControl(''),
    pv: new FormControl(''),
    sap: new FormControl(''),
    highRisk: new FormControl(false),
    interruptedLoad: new FormControl(''),
    search: new FormControl(''),
    equipment: new FormControl('',[Validators.required]),
  });

  timerInterface =  { disabled: false, value: this.form.value.timeValue, label: 'Hora', placeholder: '00:00h' }
  datapickerInterface =  { disabled: false, date: this.form.value.datapickerValue, label: 'Data', placeholder: DATE_FORMAT, required: true }
  checkBoxInterface = [
    { disabled: false, label: "Enviar dados da ocorrência por e-mail", checked:false},
  ]
  textAreaInterface = [
    { value: this.form.value.description, label: 'Descrição (Opcional)', rows: 4, placeholder: 'Insira aqui a descrição da Ocorrência' },
    { value: this.form.value.breakers, label: 'Disjuntores (Opcional)', rows: 4, placeholder: 'Insira aqui os disjuntores afetados' },
    { value: this.form.value.trips, label: 'Proteções (Opcional)', rows: 7, placeholder: 'Insira aqui as proteções' },
    { value: this.form.value.sap, label: 'Intervenções programadas (Opcional)', rows: 5, placeholder: 'Insira aqui as informações de intervenções programadas',disabled: false },
  ];
  textInterface: TextInput[] = [
    { value: this.form.value.cod, placeholder: 'Insira aqui a Sigla da linha XXX/XXX ou instalação XXX', 
      label: 'Linha ou instalação (Sigla)', disabled: false, hint: "Para busca automática preencha com uma sigla", 
      regexHint: /(^[a-z]{3}$)|(^[a-z]{3}\/[a-z]{3}$)/gi, required: true },
    {value: this.form.value.equipment, placeholder: 'Insira aqui o equipamento', label: 'Equipamento', disabled: false, required: true},
  ];
  numberSuffixInterface: NumberSuffixInput[] = [
    {value: this.form.value.pv, label: 'Parcela variável (R$/h) (Opcional)', placeholder: 'Insira aqui um valor R$/hora', disabled: false, /* suffix: "R$/hora", */ /*obs: "(Opcional)"*/},
    {value: this.form.value.interruptedLoad, label: 'Carga Interrompida (MW) (Opcional)', placeholder: 'Insira aqui um valor em MW', disabled: false, /* suffix: "R$/hora",  obs: "(Opcional)"*/},
  ]
  selectInterface = [
    { selectedItem: '', label: 'Instalação (Sigla)', placeholder: 'Selecione a instalação', options: this.optionsCod, labelShow: 'viewValue' },
    ]
  groupIds: string[] = []   

  canSearch = true;

  constructor(
    public dialogRef: MatDialogRef<ModalCreateManualOccurrenceComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    private readonly internalService: InternalService,
    private readonly toastr: ToastrService,
    private readonly eventDispatcherService: EventDispatcherService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.internalService.listSubstations().subscribe(response=>{
      this.selectInterface[0].options = response.map(substation => {return {value: substation, viewValue: substation}})
    })
    if(this.data.sendData){
      let description = 'Grupo de ocorrências \n\nEquipamentos agrupados:\n'
      this.textAreaInterface[0].value = this.form.value.description;
      let breakers = '';
      let trips = '';
      this.data.sendData.forEach((element,index) => {
        this.groupIds.push(element.id);
        breakers = element.seqEvents[0].breakers ? (breakers + element.seqEvents[0].breakers + '\n') : breakers;
        trips = element.seqEvents[0].trips ? (trips + element.seqEvents[0].trips + '\n') : trips;
        description = description + element.equipment + (index !== this.data.sendData.length - 1 ? ',' : '.')
      });

      breakers = breakers.replace(/• .*\n/gim,"")
      breakers = breakers.replace(/:/gim,"")
      breakers = breakers.replace(/\s+/gim,"\n")
      
      breakers = [...new Set(breakers.split('\n'))].join('\n')

      trips = [...new Set(trips.split(/\n(?=[0-9])/gim).map(el=>{return el.replace(/\n$/, "")}))].join('\n')
    
      this.form.get('description').setValue(description);
      this.form.get('breakers').setValue(breakers);
      this.form.get('trips').setValue(trips);


      this.textAreaInterface[0].value = this.form.value.description;
      this.textAreaInterface[1].value = this.form.value.breakers;
      this.textAreaInterface[2].value = this.form.value.trips;
    }
  }

  confirmCreate(){
    if(!this.data.sendData){
      this.dialog.open(SwalComponent, {
        disableClose: true,
        width: '352px',
        height: '200px',
        panelClass: 'custom-modalbox-swal',
        //maxHeight: '941px',
        data: {
          type: 'notification',
          cancelButton: {text: 'CANCELAR'},
          validationButton: {text: 'CRIAR'},
          title: 'Criar ocorrência manual',
          text: 'Atenção! Essa é uma ocorrência não detectada pelo SIRO, ao criar essa ocorrência será integrada ao sistema e enviada como mensageria. Deseja mesmo criar a ocorrência?'
        }
      }).afterClosed().subscribe(res=>{
        if(res){
          this.handleClick(true)

        }
      })
    }else{
      this.handleClick(true);
    }
  }

  handleClick(type): void {

    if(!type) this.dialogRef.close(type);

    else{

      const dialog = this.dialog.open(ModalLoadingComponent, {
        width: '400px',
        disableClose: true,
        data: {
          title: 'Carregando',
          text: 'Por favor espere alguns segundos',
        },
      });

      const date = new Date(this.form.value.datapickerValue)
      date.setHours(this.form.value.timeValue.substr(0, 2), this.form.value.timeValue.substr(3));

      const sendData = {
        highRisk: this.form.value.highRisk,
        interruptedLoad: this.form.value.interruptedLoad,
        date,
        substation: this.form.value.cod.toUpperCase(),
        equipment: this.form.value.equipment.toUpperCase(),
        pv: this.form.value.pv,
        region: null,
        descriptionText: this.form.value.description,
        tripsText: this.form.value.trips,
        breakersText: this.form.value.breakers,
        warningText: "",
        sapText: this.form.value.sap,
        sendEmail: this.checkBoxInterface[0].checked
      }

      if(this.data.sendData){
      this.internalService.groupOccurrences(sendData,[{param: "ids", value: this.groupIds.join()}]).subscribe(data => {
        this.toastr.show('Ocorrência criada com sucesso', 'Sucesso', { timeOut: 500, messageClass: 'success' });
        dialog.close();
        this.dialogRef.close(type);
        this.eventDispatcherService.emitEditNotifyOccurrence(true)
      }, error => {
        this.toastr.show("Tente novamente mais tarde.", 'Erro', { timeOut: 500, messageClass: 'error' })
        dialog.close();
        this.dialogRef.close(type);
      })
    }else{
      this.internalService.createOccurrences(sendData).subscribe(data => {
        this.toastr.show('Ocorrência criada com sucesso', 'Sucesso', { timeOut: 500, messageClass: 'success' });
        dialog.close();
        this.dialogRef.close(type);
        this.eventDispatcherService.emitEditNotifyOccurrence(true)
      }, error => {
        this.toastr.show("Tente novamente mais tarde.", 'Erro', { timeOut: 500, messageClass: 'error' })
        dialog.close();
        this.dialogRef.close(type);
      })
    }
    }
  }

  onCheckChange(event,index){
    this.checkBoxInterface[index].checked = event;
  }

  onToggleChange(event,field){
    this.form.get(field).setValue(event.checked);
  }

  onTogglesSearch(event,field){
    this.form.get(field).setValue(event.checked);
    if(event.checked)
      this.getPV_SI();
  }

  getTagColors(tag){
    return {content:tag, customColor: this.ARRAY_COLORS[tag]}
  }

  onValueEmitterTextAreaInput(newValue,field){
    this.form.get(field).setValue(newValue)
  }

  disabledButtons(){
    return !this.form.valid
  }

  getPV_SI(){
    const attributes = [
      {param: 'equipment', value: this.form.value.equipment},
      {param: 'substation', value: this.form.value.cod}
    ]
    this.internalService.searchManualPV_SI(attributes).subscribe((res:any)=>{
      this.form.get('pv').setValue(res.pv);
      this.form.get('sap').setValue(res.intervention)
      this.numberSuffixInterface[0].value = res.pv;
      this.numberSuffixInterface[0].disabled = true;
      this.textAreaInterface[3].value = res.intervention;
      this.textAreaInterface[3].disabled = false;
    }) 
  }

}
