import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/service/http/http.service";
import { RequestParams, RequestAttribute } from "src/utils/models/http.interface";

@Injectable({
  providedIn: 'root'
})

export class InternalService {

  private readonly endpointOccurrence: string = 'ocorrences';
  private readonly endpointCreateOccurrence: string = 'eventGroups/newOcorrence';
  private readonly endpointOccurrenceNotify: string = 'ocorrences/notify';
  private readonly endpointfetchData: string = 'eventGroups/fetchData';
  private readonly endpointNewOccurrences: string = 'ocorrences/newOcorrences';
  private readonly endpointGroupsOccurrences: string = 'eventGroups';
  private readonly endpointListFilters: string = 'eventGroups/filterList'
  private readonly endpointAddInGroup = "eventGroups/addEvent";
  private readonly endpointEndGroup = "eventGroups/endGroup";
  private readonly endpointAproveGroup = "eventGroups/aproveGroup";
  private readonly endpointLogsGroup = "eventGroups/showLogs";
  private readonly endpointRejectGroup = "eventGroups/rejectGroup";
  private readonly endpointListIntegrations = "health/avaiability";
  private readonly endpointListHistoricIntegrations = "health/history";
  private readonly endpointSearchPV_SI = "eventGroups/searchManualData";
  private readonly endpointParameterSI = "substations/updateSupervision";
  private readonly endpointGetParameterSI = "supervisions/generalRate"
  private readonly endpointDeleteParameterSI = "substations/eraseSupervision"
  private readonly endpointSupervisionList = "supervisions"
  private readonly endpointSupervisionFilterList = "supervisions/filterList"
  private readonly endpointSubstations = "substations/list";
  private readonly endpointAproveSupervision = "supervisions/aprove";
  private readonly endpointCancelSupervision = "supervisions/cancel";
  private readonly endpointAddManualSupervision = "supervisions/manualInformation";
  private readonly endpointEndSupervision = "supervisions/finish"
  private readonly endpointGroupOccurrences = "eventGroups/groupOcorrences";
  private readonly endpointGetSubstationRate = "supervisions/substationRates"
  

  constructor(private readonly httpService: HttpService) { }

  listSubstations(): Observable<[]> {
    return this.httpService.genericGet(this.endpointSubstations)

  }

  listIntegrations(){
    return this.httpService.genericGet(this.endpointListIntegrations)
  }

  listFilters(){
    return this.httpService.genericGet(this.endpointListFilters)
  }

  getOccurrences(id){
    return this.httpService.genericGet(this.endpointOccurrence,id)
  }

  editOccurrences(id,data){
    return this.httpService.genericPut(this.endpointOccurrence,data,id)
  }

  createOccurrences(data){
    return this.httpService.genericPost(this.endpointCreateOccurrence,data,'')
  }

  listOccurrences(requestParams: RequestParams, requestAttribute?: RequestAttribute[]): Observable<[]> {
    return this.httpService.genericGetList<any>(this.endpointOccurrence, requestParams, requestAttribute)
      .pipe(map((response: []) => response));
  }

  getNewOccurrences(requestParams: RequestParams, requestAttribute?: RequestAttribute[]): Observable<[]> {
    return this.httpService.genericGetList<any>(this.endpointNewOccurrences, requestParams, requestAttribute)
      .pipe(map((response: []) => response));
  }

  listGroupOccurrences(requestParams: RequestParams, requestAttribute?: RequestAttribute[]): Observable<[]> {
    return this.httpService.genericGetList<any>(this.endpointGroupsOccurrences, requestParams, requestAttribute,'page')
      .pipe(map((response: []) => response));
  }
  

  addManualOccorrence(id,data){
    return this.httpService.genericPut(this.endpointAddInGroup,data,id)
  }

  endGroup(id,data){
    return this.httpService.genericPut(this.endpointEndGroup,data,id)
  }

  aproveGroup(id,data){
    return this.httpService.genericPut(this.endpointAproveGroup,data,id)
  }

  rejectGroup(id){
    return this.httpService.genericPut(this.endpointRejectGroup,{},id)
  }

  fetchData(requestParams: RequestParams, requestAttribute?: RequestAttribute[]){ //unify routs
    return this.httpService.genericGetList<any>(this.endpointfetchData, requestParams, requestAttribute,'page')
    //return this.httpService.genericGet(this.endpointfetchData)
  }

  getNotify(){
    return this.httpService.genericGet(this.endpointOccurrenceNotify)
  }

  getLastMessages(requestParams: RequestParams){
    this.httpService.genericGetListTable(this.endpointLogsGroup,requestParams)
  }

  searchManualPV_SI(attributes: RequestAttribute[]){
    return this.httpService.genericGetParams(this.endpointSearchPV_SI,attributes)
  }

  updateParameter(data,requestAttribute: RequestAttribute[]){
    return this.httpService.genericPutBody(this.endpointParameterSI,data,requestAttribute)
  }

  deleteParameter(data,requestAttribute: RequestAttribute[]){
    return this.httpService.genericPutBody(this.endpointDeleteParameterSI,data,requestAttribute)
  }

  getParameter(){
    return this.httpService.genericGet(this.endpointGetParameterSI)
  }

  getSupervisionValue(requestParams: RequestParams, requestAttribute?: RequestAttribute[]): Observable<[]> {
    return this.httpService.genericGetList<any>(this.endpointGetSubstationRate, requestParams, requestAttribute)
      .pipe(map((response: []) => response));
  }

  listSupervision(requestParams: RequestParams, requestAttribute?: RequestAttribute[]): Observable<[]> {
    return this.httpService.genericGetList<any>(this.endpointSupervisionList, requestParams, requestAttribute,'page')
      .pipe(map((response: []) => response));
  }

  addManualSupervision(id,data){
    return this.httpService.genericPut(this.endpointAddManualSupervision,data,id)
  }

  listFiltersSupervision(){
    return this.httpService.genericGet(this.endpointSupervisionFilterList)
  }

  aproveSupervision(id,data){
    return this.httpService.genericPut(this.endpointAproveSupervision,data,id)
  }

  cancelSupervision(id,data){
    return this.httpService.genericPut(this.endpointCancelSupervision,data,id)
  }

  endSupervision(id,data){
    return this.httpService.genericPut(this.endpointEndSupervision,data,id)
  }

 groupOccurrences(data,requestAttribute: RequestAttribute[]){
    return this.httpService.genericPutBody(this.endpointGroupOccurrences,data,requestAttribute)
  }

  //Util functions

  formatDate(date){
    const formatDate = new Date(date);
    return ` ${formatDate.toLocaleDateString('pt-br')} às ${formatDate.toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'})}` 
  }

   calculateInterval(dataInicial, dataFinal) {
    const diffEmMilissegundos = dataFinal - dataInicial;
    const diffEmMinutos = diffEmMilissegundos / (1000 * 60);
    const hours = Math.floor(diffEmMinutos / 60);
    const minutes = Math.floor(diffEmMinutos % 60);
    
    const formattedDuration = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    return formattedDuration;
  }

  milissecondsToInterval(milissegundos) {
    const segundos = Math.floor(milissegundos / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);
  
    const horasFormatadas = String(horas).padStart(2, '0');
    const minutosFormatados = String(minutos % 60).padStart(2, '0');
    return `${horasFormatadas}:${minutosFormatados}`;
  }

  intervalToMilisseconds(intervalo: string): number {

    if(intervalo){
    
      const [horas, minutos] = intervalo.split(':').map(Number);
      const milissegundosPorHora = 60 * 60 * 1000;
      const milissegundosPorMinuto = 60 * 1000;
  
      const totalMilissegundos = horas * milissegundosPorHora + minutos * milissegundosPorMinuto;
      return totalMilissegundos;
    }else{
      return null
    }
  }

}