<div class="tabs-container">
   <ul class="tab">
        <li
            *ngFor="let tab of tabs"
            (click)="selectTab(tab)"
            [class.active]="tab.active"
            [class.disabled]="tab.disabled" 
        >
            {{ tab.title }}
        </li>
   </ul>

   <div class="divider-tab">
        <divider></divider>
   </div>
   
   <div class="content-tab">
        <span class="content">
            <ng-content></ng-content>
        </span>
   </div>  
</div>