export interface RequestAttribute {
  param: string;
  value: string;
}

export interface RequestParams {
  filter?: string;
  sort: string;
  order?: 'desc' | 'asc' | string;
  page?: number;
  limit: number;
  skip: number;
}

export interface RequestParamsLogStatus {
  endpoint: string;
  status: string;
  sort: string;
  limit: number;
}

export interface RequestParamsLog {
  endpoint: string;
  sort: string;
  limit: number;
}

export interface RequestEquipamento {
  type: string;
}

export interface RequestCenario {
  scenarioId: string;
}
