import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';
import { TextInput } from '../../../../../utils/models/input.interface';

@Component({
  selector: 'time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TimeInputComponent,
    },
  ],
})
export class TimeInputComponent implements OnInit, ControlValueAccessor {

  @Input() timerInput: TextInput;

  @Output() valueEmitter: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  writeValue(value: any): void {
    this.timerInput.value = value;
  }

  onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
    if(this.timerInput.value === undefined || this.timerInput.value === null) {
      this.timerInput.value = '';
    }

  }

  setNewValue(event): void {
    this.onChange(event);
    this.valueEmitter.emit(event);
  }

  hasValue(): boolean {
    if (this.timerInput.value !== '') {
      return true;
    } else {
      return false;
    }
  }

}
