<div class="card-container" [ngStyle]="{'width': width, 'height': height}">
    <div class="content">
        <div class="header-card">
            <span class="header-text">
                {{ title }}
            </span>

            <span>
                <mat-icon *ngIf="icon == 'success'" class="icon" svgIcon="success"></mat-icon>
                <mat-icon *ngIf="icon == 'error'" class="icon" svgIcon="error"></mat-icon>
            </span>
        </div>
        <divider *ngIf="divider"></divider>
        <div class="card-content">
            <ng-content [select]="tag"></ng-content>
        </div>
    </div> 
</div>