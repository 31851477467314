export const environment = {
  production: false,
  //apiUrl: 'https://broken-night-7120.getsandbox.com',
  //apiUrl:'https://63a59f6af8f3f6d4abfb383d.mockapi.io/api-portfolio',
  apiUrl:'https://iea-api-dev.enacloud.me',

  apiUrlBuffer: '',

  redirectUri: 'https://iea-dev.enacloud.me',
  client: 'e19c88a9-89e4-4aae-b6fb-f2a8c9fc5837',
  authority: 'https://login.microsoftonline.com/efa10a2e-43c9-4e1e-8b6f-e1dd91403848',
  /*client: 'f165836e-82ca-4733-9000-0b090ec4cfbe',
  authority: 'https://login.microsoftonline.com/c7a4317c-6ed8-4a45-989d-32c9b3690628',*/
};
