<div class="radio-container" *ngFor="let option of radioButton.options; index as i">
    <input #radioButtonInput type="checkbox" [(ngModel)]="option.checked" (click)="onClicked(option)">
    <button 
        class="radio-button-input"
        [ngClass]="{
            'checked': option.checked
        }"
        (click)="radioButtonInput.click()"
        [disabled]="option.disabled"></button>
    <span class="label-input">{{ option.viewValue }}</span>
</div>
