<mat-select class="font-default"
  msInfiniteScroll
  (focus)="setTouched()"
  (infiniteScroll)="infiniteScroll()"
  [complete]="complete"
  [(ngModel)]="_value"
  (selectionChange)="select()"
  [placeholder]="_placeholder"
  [disabled]="disabledAux"
  [required]="_required"
  [multiple]="multiple"
  #multiSelect>
  <mat-option>
    <ngx-mat-select-search *ngIf="filterSearch"
      [formControl]="formAux"
      [placeholderLabel]="'Pesquisar...'"
      [noEntriesFoundLabel]="'Opção não encontrada'"
      [disableScrollToActiveOnOptionsChanged]="true"
      [preventHomeEndKeyPropagation]="true"
      [searching]="loading"
      [clearSearchInput]="false">
      <mat-icon (click)="clearSelection()" ngxMatSelectSearchClear class="material-icons-outlined" style="vertical-align: middle !important;">close</mat-icon>
    </ngx-mat-select-search>
  </mat-option>
  <mat-option *ngIf=hasEmptyOption>--</mat-option>
  <mat-option
    *ngFor="let option of _options"
    [disabled]="returnIfOptionIsDisabled(option[labelShow])"
    [value]="!!returnVariable ? option[returnVariable] : option">
    {{ labelShow ? option[labelShow].name? option[labelShow].name : option[labelShow] : option }}
  </mat-option>
  <mat-option *ngIf="isTableFilter || hasClearOption" style="height: 52px !important;"></mat-option>
  <div *ngIf="isTableFilter" [style.width]="multiple ? '120%' : 'calc(120% - 24px)'" [style.top.px]="_options.length < 4 ? ((_options.length+1)*42)-1 : 213" class="cont">
    <button mat-raised-button class="limpar" [disabled]="!_value || _value.length == 0" (click)="limpar()"><mat-icon svgIcon="not-filter"></mat-icon></button>
    <button mat-stroked-button class="filtrar" [disabled]="compare(_value, initialData) || _options.length == 0" (click)="filtrar()">Atualizar</button>
  </div>

  <div *ngIf="hasClearOption" [style.width]="multiple ? '120%' : 'calc(120% - 24px)'" [style.top.px]="_options.length < 4 ? ((_options.length+1)*42)-1 : 213" class="cont">
    <button mat-stroked-button class="filtrar" [disabled]="!_value || _value.length == 0" (click)="limpar()">LIMPAR</button>
  </div>

</mat-select>
