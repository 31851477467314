<div class="modal-container">
    <div class="head">
      <div class="title">
        Gerenciar perda de supervisão
      </div>
      <mat-icon class="icon" (click)="handleClick(false)">close</mat-icon>
    </div>
    <div class="line"></div>

    <div class="content">
        <div class="text">
            Olá usuário! Para Gerenciar a perda de supervisão verifique os dados:
        </div>
        <div class="subtitle">
            INFORMAÇÕES DA PERDA DE SUPERVISÃO
        </div>

        <div class="title-infos">
            <span class="id-supervision">PS{{supervision.id}}</span>
            <mat-divider class="vertical-divider" [vertical]="true"></mat-divider>
            <span class="name-supervision">{{supervision.translatedSubstation}} {{supervision.equipment}}</span>
            <tags [tag]="getTagColors(supervision.status)"></tags>

        </div>

        <div class="lt" *ngIf="supervision.highRisk"><mat-icon *ngIf="true" class="icon-lt" svgIcon="alert-yellon"></mat-icon><span>LT DE ALTO RISCO</span></div>


        <form [formGroup]="form" class="data-inputs">
            
            <div class="time-inputs">
                <datapicker-input [datapicker]="datapickerInterface" [disabled]="true" formControlName="datapickerValue"></datapicker-input>
                <time-input [timerInput]="timerInterface"></time-input>
            </div>

            <div class="text-input">
                <text-input [textInput]="textInterface[0]"></text-input>
            </div>

            <div class="line"></div>
            
            <div class="text-infos">
                <textarea-input [textAreaInput]="textAreaInterface[0]" (valueEmitter)="onValueEmitterTextAreaInput($event, 'description')"></textarea-input>
            </div>

            <div class="line no-margin-top"></div>
 
        </form>
    </div>

    <div class="action">
      <button-induxtry *ngIf="data?.cancelButton" (action)="handleClick('Rejeitada')" 
          [buttonInduxtry]="{ label: data?.cancelButton?.text, customStyle: 'cancel', showLabel:true, disabled: data.disableCancel }">
      </button-induxtry >
      <button-induxtry *ngIf="data?.validationButton" (action)="handleClick('Aprovado')" 
          [buttonInduxtry]="{ label: data?.validationButton?.text, customStyle: 'primary', showLabel:true, disabled: data.disableAprove }">
      </button-induxtry>
    </div>
  </div>
  