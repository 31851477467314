import { HttpService } from './../../tables/dynamic-table/service/httpService.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NgControl,
  Validators,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatSelect } from '@angular/material/select';

import {
  RequestAttribute,
  RequestParams,
} from '../../../../../utils/models/http.interface';

export function validateCounterRange(c: FormControl) {
  const err = {
    rangeError: {
      given: c.value,
    },
  };
  return c.value === undefined || c.value === null ? err : null;
}

const SLEEP_TIME = 300;
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'select-infinite-scroll-search',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectInfiniteScrollSearchComponent,
    },
  ],
  templateUrl: './select-infinite-scroll-search.component.html',
  styleUrls: ['./select-infinite-scroll-search.component.scss'],
})
export class SelectInfiniteScrollSearchComponent
  implements
    OnInit,
    OnChanges,
    MatFormFieldControl<any>,
    ControlValueAccessor,
    AfterViewInit,
    OnDestroy,
    Validators {

  @Input()
  set options(arr: any[]) {
    if (arr) {
      this._options = [...arr];
      this._options = this._options.filter((o) => !!o);
      this._oprtionOriginal = [...arr];
      this._oprtionOriginal = this._oprtionOriginal.filter((o) => !!o);
    }
  }

  @Input()
  set value(obj: any | null) {
    this._value = obj;
  }
  get value(): any | null {
    if (this._value) {
      return this._value;
    } else {
      return 'teste';
    }
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get errorState() {
    const controlDirectiveAux: any = this.controlDirective;
    let parent = controlDirectiveAux._parent;

    while (parent._parent) {
      parent = parent._parent;
    }
    
    if (parent.submitted) {
      return !!this.controlDirective.invalid && !this.controlDirective.disabled;
    } else {
      return !!this.controlDirective.invalid && this.touched;
    }
  }
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get empty() {
    return !this._value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }

  @Input() hasClearOption;


  constructor(
    private readonly httpService: HttpService,
    private readonly fm: FocusMonitor,
    private readonly elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public controlDirective: NgControl
  ) {
    this.sleep(SLEEP_TIME).then(() => {
      if (!this.internalFilter && !!this.endpoint && !!this._value) {
        if (this.returnVariable) {
          this.httpService.genericGet<any>(this.endpoint, this._value)
            .subscribe((object: any[] | any) => {
              this.validObjectId(object);
              
            });
        } else {
          this.httpService
            .genericGet<any>(this.endpoint, this._value.id)
            .subscribe((object: any[] | any) => {
              this.writeValue(object);
              this.validObjectId(object, false);
            });
        }
      }
    });
    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    if (this.controlDirective) {
      this.controlDirective.valueAccessor = this;
    }
  }

  validObjectId(object, getId = true) {
    this._options.unshift(object);
    let index = -1;

    if(getId) {
      index = this._options.findIndex(
        (option: any, i: number, options: any[]) => {
          return (
            option[this.returnVariable] ===
              options[0][this.returnVariable] && i !== 0
          );
        }
      );
    } else {
      index = this._options.findIndex(
        (option: any, i: number, options: any[]) => {
          return option.id === options[0].id && i !== 0;
        }
      );
    }

    if (index !== -1) {
      this._options.splice(index, 1);
    }
  }

  static nextId = 0;

  _options;
  _oprtionOriginal;

  @Input() internalFilter: false;

  @Input() disabledOptions: any;
  @Input() endpoint: string;
  @Input() label: string;
  @Input() multiple: boolean;
  @Input() enableInfScroll = true;
  @Input() labelShow: string;
  @Input() parts;
  @Input() hasCount: boolean;
  @Input() returnVariable: string = undefined;
  @Input() alphabeticalOrder = false;

  @Input() inputSearch: string = undefined;
  @Input() filterSearch = true;
  @Input() hasEmptyOption: false;
  @Input() isTableFilter = false;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionTouched: EventEmitter<any> = new EventEmitter<any>();
  @Output() optionEmmiter: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputSearchEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('multiSelect') multiSelect: MatSelect;

  complete = false;

  touched = false;

  @Input() attributes: RequestAttribute[] = [];
  _value: any = [];
  _required = false;
  _placeholder: string;

  stateChanges = new Subject<void>();
  ngControl: NgControl = null;
  focused = false;
  idSelect = SelectInfiniteScrollSearchComponent.nextId + 1;

  @HostBinding()
  id = `example-tel-input-${this.idSelect}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  private _disabled = false;
  @Input() disabledAux = false;
  // tslint:disable-next-line: variable-name
  private readonly _onDestroy = new Subject<void>();

  public formAux: FormControl = new FormControl();

  loading = false;
  requestParams: RequestParams = {
    sort: '',
    order: '',
    skip: 0,
    limit: 30,
  };

  initialData: any = [];
  restore = true;

  filtrar() {
    this.filterEvent.emit(this._value);
    this.restore = false;
    this.multiSelect.toggle();
  }

  limpar() {
    this.writeValue([]);
    this.stateChanges.next();
    this.clearFilter.emit();
    this.restore = false;
    this.multiSelect.toggle();
  }

  validate(c: AbstractControl): { [key: string]: any } {
   return this.required ? Validators.required(c) : null;
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  clearSelection() {
    this._value = null;
    this._options = [];
    this.select();
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'div') {
      this.elRef.nativeElement.querySelector('div').focus();
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.disabledAux = isDisabled;
  }

  writeValue(value: any) {
    this._value = value;
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  ngAfterViewInit(): void {
    this.controlDirective.valueChanges.subscribe(() => {
      this.setTouched();
    });

    if (this.isTableFilter) {
      this.multiSelect.openedChange.subscribe((value) => {
        if (value) {
          this.initialData = this._value;
        }
        if (!value && this.restore) {
          this._value = this.initialData;
          this.propagateChange(this._value);
        }
        this.restore = true;
      });
    }
  }

  select() {
    this.propagateChange(this._value);
    this.selectionChange.emit(this._value);
  }

  setTouched() {
    this.touched = true;
    this.selectionTouched.emit();
    this.stateChanges.next();
  }

  infiniteScroll() {
    if (!this.loading && this.enableInfScroll && !this.internalFilter) {
      this.loading = true;
      this.requestParams.order = this.alphabeticalOrder ? 'asc' : '';
      this.requestParams.sort = this.alphabeticalOrder ? this.labelShow : '';
      this.requestParams.page++;
      const attributesRequest = this.attributes.filter((a) => a.value);

      if (!this.formAux.value && !this.complete) {
        this.httpService
          .genericGetList<[]>(
            this.endpoint,
            this.requestParams,
            attributesRequest
          )
          .subscribe((obj: any[] | any) => {
            this.loading = false;
            if (this.hasCount) {
              this.validObjectInfiniteScroll(obj.rows, obj.rows.length);
            } else {
              this.validObjectInfiniteScroll(obj, obj.length);
            }
            
            this.optionEmmiter.emit(this._options);
          });
      }
    }
  }

  validObjectInfiniteScroll(object, lenght) {
    const index = object.findIndex((option: any) => {
      return option.id === this._options[0].id;
    });

    if(index !== -1) {
      object.splice(index, 1);
    }

    this._options = this._options.concat(object);

    this.complete = (lenght ===  0);
  }

  returnIfOptionIsDisabled(option: any) {
    if (this.disabledOptions && this.disabledOptions.indexOf(+option) !== -1) {
      return true;
    }
    return false;
  }

  ngOnChanges() {
    if (this.inputSearch) {
      this.formAux.setValue(this.inputSearch);
    }
  }

  ngOnInit() {
    this.attributes.push({
      param: this.labelShow,
      value: this.formAux.value,
    });
    this.formAux.valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        debounceTime(SLEEP_TIME),
        distinctUntilChanged()
      )
      .subscribe((_subs) => {
        this.loading = true;
        if (!this.internalFilter) {
          this.attributes[this.attributes.length - 1].value =
            this.formAux.value;
          this.requestParams.page = 1;
          this.requestParams.order = this.alphabeticalOrder ? 'asc' : '';
          this.requestParams.sort = this.alphabeticalOrder
            ? this.labelShow
            : '';
          this.httpService
            .genericGetList<[]>(
              this.endpoint,
              this.requestParams,
              this.attributes
            )
            .subscribe((option: any[] | any) => {
              if (this.hasCount) {
                this._options = option.rows;
              } else {
                this._options = option;
              }
              this.optionEmmiter.emit(this._options);
              this.inputSearchEmitter.emit(this.formAux.value);
              this.loading = false;
            });
        } else {
          if (!!this.formAux.value && this.labelShow) {
            this._options = this._oprtionOriginal.filter((option: string) =>
              option[this.labelShow]
                .toString()
                .toLowerCase()
                .includes(this.formAux.value.toLowerCase())
            );
          } else if (!!this.formAux.value) {
            this._options = this._oprtionOriginal.filter((option: string) =>
              option
                .toString()
                .toLowerCase()
                .includes(this.formAux.value.toLowerCase())
            );
          } else {
            this._options = [...this._oprtionOriginal];
          }
          this.loading = false;
        }
      });
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  compare(arr1, arr2) {
    if (arr1 && arr2) {
      return (
        arr1.length === arr2.length &&
        arr1.every((element, index) => {
          return element === arr2[index];
        })
      );
    }
    return false;
  }
}
