<div class="modal-container">
    <div class="head-line">
    <div class="head">
        <div class="left">
            <span class="id-occurrence">ID{{occurrence.parentDataId ? occurrence.parentDataId : occurrence.id}}</span>
            <mat-divider class="vertical-divider" [vertical]="true"></mat-divider>
            <span class="name-occurrence">{{occurrence.name}}</span>
            <tags [tag]="getTagColors(occurrence)"></tags>
        </div>

        <div class="right">
            <span *ngIf="occurrence.endDate" class="end-date-occurrence">
                <span class="blue-label">Finalizado em: </span>  <span class="date">{{formatDate(occurrence.endDate ? occurrence.endDate : occurrence.end)}}</span>
                <mat-divider class="vertical-divider" [vertical]="true"></mat-divider>
            </span>
            <span class="start-date-occurrence">
                <span class="blue-label">Início: </span>  <span class="date">{{formatDate(occurrence.startDate ? occurrence.startDate : occurrence.start)}}</span>
            </span>
            <mat-divider class="vertical-divider" [vertical]="true"></mat-divider>
        </div>
      <mat-icon class="icon" (click)="handleClick(false)">close</mat-icon>
    </div>
    <div class="line"></div>
    </div>


    <div class="content">
       <div class="title">
        INTERAÇÕES DO USUÁRIO
       </div>

       <div class="timeline">
        <div class="icon-label">                
            <div class="circle" [ngStyle]="{'background-color': getIconColor('validation')}"><mat-icon class="icon" svgIcon="notify-bell"></mat-icon></div>    
            <div class="label-timeline" [ngStyle]="{'color': getIconColor('validation')}">Validação Inicial</div>
        </div>
 
        <div class="line-timeline" [ngStyle]="{'border-color':getIconColor('diagnostic')}"></div>
        <div class="icon-label">
            <div class="circle" [ngStyle]="{'background-color':getIconColor('diagnostic')}"><mat-icon class="icon" svgIcon="tool"></mat-icon></div>
            <div class="label-timeline" [ngStyle]="{'color': getIconColor('diagnostic')}">Diagnóstico</div>
        </div>

        <div class="line-timeline" [ngStyle]="{'border-color':getIconColor('finished')}"></div>
        <div class="icon-label">
            <div class="circle" [ngStyle]="{'background-color':getIconColor('finished')}"><mat-icon class="icon" svgIcon="blue-success"></mat-icon></div>
            <div class="label-timeline" [ngStyle]="{'color': getIconColor('finished')}">Finalizada</div>
        </div>
    </div>


    <div>
        <dynamic-table
        #table
        [dynamicTable]="logsDynamicTable"
        [hasPaginate]="true"
        [attributes]="atributtesTable"
      >
      </dynamic-table>
    </div>
    </div>



</div>