<div class="container" *ngIf="options.messageClass != 'notification' && options.messageClass != 'basic'">
  <mat-icon class="icon" *ngIf="options.messageClass != 'basic'" [svgIcon]="options.messageClass"></mat-icon>
  <div class="color {{options.messageClass}}"></div>
  <div class="title" [class.left]="options.messageClass != 'basic'">
      {{ title }}
  </div>
  <div class="message" [class.left]="options.messageClass != 'basic'">
    {{ message }}
  </div>
  <mat-icon class="close-button" (click)="remove()">close</mat-icon>
</div>

<div class="container-notification" *ngIf="options.messageClass == 'notification'">
  <div class="img-notification"></div>
  <div class="title-notification">
      {{ title }}
    </div>
    <div class="message-notification">
      {{ message }}
    </div>
    <mat-icon (click)="remove()" class="close-button">close</mat-icon>
</div>

<div class="container-notification-alert" *ngIf="options.messageClass == 'basic'">
  <mat-icon class="icon notification-icon" [svgIcon]="'notification'"></mat-icon>
  <div class="color {{options.messageClass}}"></div>
  <div class="notification-title" [class.left]="options.messageClass != 'basic'">
      {{ title }}
  </div>

  <div class="row-info-name">
    <span class="id">{{data.id}}</span>
    <mat-divider class="vertical-divider" [vertical]="true"></mat-divider>
    <span class="equipment"> {{data.station}} {{data.equipment}} </span>
  </div>

  <div class="description"> {{data.description}} </div>

  <div class="row-date-button">
    <span class="date">{{data.dateString}}</span>
    <span class="simple-button" (click)="action(true)">VER MAIS</span>
  </div>

  <mat-icon class="close-button" (click)="remove()">close</mat-icon>
</div>