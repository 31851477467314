<div class="modal-container">
  <div class="head">
    <div class="title">
      Agrupar seleção
    </div>
    <mat-icon class="icon" (click)="handleClick(false)">close</mat-icon>
  </div>
  <div class="line"></div>

  <div class="content-modal">
  <div class="content">
      <div class="text">
        Olá usuário! Para começar, confirme as ocorrências a serem agrupadas:
      </div>     
  </div>

  <div class="text">
    <div *ngFor="let occurrence of data.listGroup; index as idx" class="checkbox">            
      <checkbox [checkbox]="checkBoxInterface[idx]" (onChange)="onCheckChange($event,occurrence)"></checkbox>
      <div class="id-checkbox">ID{{occurrence.parentDataId}}</div>
      <mat-divider class="vertical-divider" [vertical]="true"></mat-divider>
      <div class="label">{{occurrence.name}}</div>

  </div>
  </div>
 <!--  <div class="action">
    <button-induxtry *ngIf="data?.cancelButton" (action)="handleClick(false)" 
        [buttonInduxtry]="{ label: data?.cancelButton?.text, customStyle: 'cancel' }">
    </button-induxtry >
    <button-induxtry *ngIf="data?.validationButton" (action)="handleClick(false)" 
        [buttonInduxtry]="{ label: data?.validationButton?.text }">
    </button-induxtry>
  </div> -->
  </div>
  <div class="line"></div>

  <div class="action">
    <button-induxtry *ngIf="data?.cancelButton" (action)="handleClick(false)" 
        [buttonInduxtry]="{ label: data?.cancelButton?.text, customStyle: 'cancel', showLabel:true, disabled: data.disableCancel }">
    </button-induxtry >
    <button-induxtry *ngIf="data?.validationButton" (action)="handleClick(listGroup)" 
        [buttonInduxtry]="{ label: data?.validationButton?.text, customStyle: 'primary', showLabel:true, disabled: disabled }">
    </button-induxtry>
  </div>
</div>
