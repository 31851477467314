import { Component, Input } from '@angular/core';

@Component({
  selector: 'tab-induxtry',
  templateUrl: './tab-induxtry.component.html',
  styleUrls: ['./tab-induxtry.component.scss']
})
export class TabInduxtryComponent {

  @Input() title: string = '';
  @Input() active = false;
  @Input() disabled = false;

}
