import { DataSource } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import {
  RequestAttribute,
  RequestParams,
} from '../../../../../utils/models/http.interface';

import { mensagem } from '../../../../../utils/mensagens/mensagens';
import moment from 'moment';

import { ModalLoadingComponent } from '../../loads/modal-loading/modal-loading.component';

/**
 * Data source for the DynamicTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class DynamicTableDataSource extends DataSource<any> {
  private readonly lessonsSubject = new BehaviorSubject<any[]>([]);
  private readonly loadingSubject = new BehaviorSubject<boolean>(false);
  private readonly countSubject = new BehaviorSubject<number>(0);
  private readonly filterSubject = new BehaviorSubject<any>({});

  public loading$ = this.loadingSubject.asObservable();
  public count$ = this.countSubject.asObservable();
  public data$ = this.lessonsSubject.asObservable(); 
  public filters$ = this.filterSubject.asObservable();

  dialogRef;
  loading = true;
  mensagens = mensagem;
  editDefault;

  constructor(private readonly httpService, public dialog: MatDialog) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<any[]> {
    return this.lessonsSubject.asObservable();
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.lessonsSubject.complete();
    this.loadingSubject.complete();
  }

  loadRequestOrder(data) {
    Promise.resolve(true).then(() => {
      return this.lessonsSubject.next(data);
    });
  }

  loadRequest(
    endpoint: string,
    requestParams: RequestParams,
    fixedAttribute?: RequestAttribute[],
    filtersAttribute?: RequestAttribute[]
  ) {
    this.dialogRef = this.dialog.open(ModalLoadingComponent, {
      autoFocus: false,
      disableClose: true,
      width: '400px',
      data: {
        title: 'Carregando',
        text: this.mensagens.MENSAGEM_MODAL_LOADING,
      },
    });

    this.loadingSubject.next(true);
    this.loading = true;

    this.httpService
      .genericGetListTable(
        endpoint,
        requestParams,
        fixedAttribute,
        filtersAttribute
      )
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((result: any) => {
        this.loading = false;
        this.dialogRef.close();
        if(result.total)
          this.countSubject.next(result.total);
        if(result.count)
          this.countSubject.next(result.count);

        return this.lessonsSubject.next(result.rows);
      });
  }

  formatDate = (date: string): string => {
    return moment(new Date(date)).format('DD/MM');
  };

  loadFilters(
    endpoint: string,
    requestParams: RequestParams,
    attribute?: RequestAttribute[],
    endPointFilters?: string
  ) {
    const end = endPointFilters ? endPointFilters : `${endpoint}/uniqueAttributes`;

    this.httpService
      .genericGetList(end, requestParams, attribute)
      .subscribe((lessons: any) => {
        let result = [];
        if (lessons.length === undefined) {
          result.push(lessons);
        } else if (lessons[0] && lessons[0].user) {
          result = lessons;
        }
        return this.filterSubject.next(result);
      });
  }

  returnLoading() {
    return this.loading;
  }

  setFilteredData(data: any[]) {
    this.lessonsSubject.next(data);
  }
}
