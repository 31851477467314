<mat-form-field [floatLabel]="'always'" appearance="outline" class="custom-timer-input">
    <mat-label > <span [ngClass]="{'mat-label-date': timerInput.disabled}" >{{ timerInput.label }} <mat-icon [style.margin-left]="'2px'" *ngIf="timerInput.disabled">lock</mat-icon></span> </mat-label>
    <div class="timer-container">
        <input matInput
            [placeholder]="timerInput.placeholder"
            [format]="24"
            [(ngModel)]="timerInput.value"
            [ngxTimepicker]="toggleTimepicker"
            [disabled]="timerInput.disabled"
            [disableClick]="timerInput.disabled"
            readonly
            />
<!--             <span *ngIf="timerInput.value !== ''"  class="suffix-time">{{ ' h' }}</span>
 -->    </div>

    <ngx-material-timepicker-toggle
        matSuffix
        [for]="toggleTimepicker"
        [disabled]="timerInput.disabled"
    ></ngx-material-timepicker-toggle>
    <ngx-material-timepicker (timeSet)="setNewValue($event)" #toggleTimepicker [defaultTime]="timerInput.value"></ngx-material-timepicker>
</mat-form-field>
