import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectInput } from '../../../../../utils/models/input.interface';

@Component({
  selector: 'select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectInputComponent,
    },
  ],
})
export class SelectInputComponent implements OnInit, ControlValueAccessor {

  @Input() selectInput: SelectInput;

  @Output() valueEmitter: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  writeValue(value: any): void {
    this.selectInput.value = value;
  }

  onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
  }

  setNewValue() {
    this.onChange(this.selectInput.value);
    this.valueEmitter.emit({option: this.selectInput.value});
  }
}
