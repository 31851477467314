<div class="chips-container">
    <div class="chips">
        <mat-icon
            *ngIf="chips.icon"
            class="icon-left"
            [svgIcon]="chips.icon"
        ></mat-icon>
        <span class="label">{{ chips.label }}</span>
        <mat-icon
            *ngIf="chips.close"
            class="close"
            svgIcon="Cancel Small"
            (click)="closeChip()"
        ></mat-icon>
    </div>
</div>