<div class="multi-select">
    <mat-form-field [floatLabel]="'always'" appearance="outline">
        <mat-label>{{ multiSelectInput.label }}</mat-label>
        <mat-select
            #multipleSelectInput
            style="font-size:12px; font-weight:400"
            [placeholder]=" multiSelectInput.placeholder"
            [disabled]=" multiSelectInput.disabled"
            [(ngModel)]=" multiSelectInput.selectedItem"
            (selectionChange)="setNewValue($event)"
            multiple
        >
        <mat-select-trigger class="select-trigger">
            <span class="trigger-data" *ngFor="let field of multiSelectInput.selectedItem | slice:0:max">
                {{ field ? field?.viewValue : '' }}
                <button
                    class="close-icon"
                    (click)="removeOptionSelected($event, field)"
                    style="background-image: url('../../../../../../assets/icons/styledIcons/CancelSmall.svg');"
                ></button>
            </span>

            <span class="trigger-counter" *ngIf="multiSelectInput.selectedItem?.length > max">
                +{{multiSelectInput.selectedItem.length - max }}
            </span>
        </mat-select-trigger>
          <mat-option
                class="option-container"
                *ngFor="let option of multiSelectInput.options; index as i"
                [value]="option"
            >
            <span>
                {{option?.viewValue}}
            </span>
          </mat-option>
        </mat-select>
    </mat-form-field>
</div>
