<div class="modal-container">
  <div class="head">
    <div class="title">
      <mat-icon *ngIf="data?.type && data?.type !== 'notification'" [svgIcon]="data?.type"></mat-icon>
      <mat-icon *ngIf="data?.type == 'notification'" style="width: 24px !important; height: 24px !important;" [svgIcon]="data?.type"></mat-icon>
      {{ data?.title }}
    </div>
    <mat-icon class="icon" (click)="handleClick(false)">close</mat-icon>
  </div>
  <div class="line"></div>
  <div class="text" *ngIf="data?.text">
    {{ data?.text }}
    <br/><br/>
    {{ data?.pergunta }}
  </div>
  <div class="action">
    <button-induxtry *ngIf="data?.cancelButton" (action)="handleClick(false)" 
        [buttonInduxtry]="{ label: data?.cancelButton?.text, customStyle: 'cancel' }">
    </button-induxtry >
    <button-induxtry *ngIf="data?.validationButton" (action)="handleClick(true)" 
        [buttonInduxtry]="{ label: data?.validationButton?.text }">
    </button-induxtry>
  </div>
</div>
