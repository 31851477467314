<div class="container-tooltip">
    <div
        [placement]="tooltip.position" 
        [ngbTooltip]="tooltip.text"
        tooltipClass="induxtry-tooltip"
        container="body"
        style="display: inline-block;"
        #tooltipComponent="ngbTooltip"
        >
        
        <ng-content></ng-content>

    </div>
</div>