<div class="switch-container">
    <input #switchInput type="checkbox" [(ngModel)]="switch.checked" (click)="changedSwitch()">
    <span class="label-switch"> {{ switch.label }}</span>
    <button 
        class="switch"
        [ngClass]="{
            'on': switch.checked
        }"
        role="switch"
        [disabled]="switch.disabled"
        (click)="switchInput.click()"
    ></button>
</div>