import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextAreaInput } from '../../../../../utils/models/input.interface';
@Component({
  selector: 'textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextareaInputComponent,
    },
  ],
})
export class TextareaInputComponent implements OnInit, ControlValueAccessor {

  @Input() textAreaInput: TextAreaInput;

  @Output() valueEmitter: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  writeValue(value: any): void {
    this.textAreaInput.value = value;
  }

  onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
  }

  setNewValue() {
    this.onChange(this.textAreaInput.value);
    this.valueEmitter.emit(this.textAreaInput.value);
  }
}
