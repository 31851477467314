<div class="time-picker">
    <div class="hours">
        <button
            (click)="increaseHours()"
            class="button-time"
            style="background-image: url('../../../../assets/icons/styledIcons/ChevronUp.svg');">
        </button>
    
        <input type="text" name="1" [(ngModel)]="_hour" class="input" (change)="setNewHour()" (focus)="setOldValue('hour')">
    
        <button 
            (click)="decreaseHours()" 
            class="button-time"
            style="background-image: url('../../../../assets/icons/styledIcons/ChevronDown.svg');">
        </button>

    </div>

    <div class="separator"> : </div>

	<div class="minutes">
        <button 
            (click)="increaseMinutes()" 
            class="button-time"
            style="background-image: url('../../../../assets/icons/styledIcons/ChevronUp.svg');">
        </button>
    
        <input type="text" name="1" [(ngModel)]="_minute" class="input" (change)="setNewMinute()" (focus)="setOldValue('minute')">
    
        <button 
            (click)="decreaseMinutes()"
            class="button-time"
            style="background-image: url('../../../../assets/icons/styledIcons/ChevronDown.svg');">
        </button>
    </div>
</div>