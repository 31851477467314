import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DynamicTable } from 'src/utils/models/table.interface';
import  logsTable  from 'src/utils/constants/layout/logs-table.json'
import { RequestAttribute } from 'src/utils/models/http.interface';
const DATE_FORMAT = 'DD/MM/AAAA';

@Component({
  selector: 'app-modal-finish-manual-occurrence',
  templateUrl: './modal-logs.component.html',
  styleUrls: ['./modal-logs.component.scss']
})
export class ModalLogsComponent implements OnInit {
  FINISH_COLOR =  { background: "#EDFDF4", content: '#17B55F' }
  WAITING_COLOR = { background: "#E0E9F6", content: "#102956"}
  CANCELED_COLOR = { background: "#FFD9D6", content: "#660700"}  
  NEW_COLOR = { background: "#FDF1D8", content: "#5F4407"}
  GROUP_COLOR = { background: "#E5DAFB", content: "#250A5C"}

  ARRAY_COLORS = {"Agrupada": this.GROUP_COLOR,"Finalizado": this.FINISH_COLOR, "Finalizada": this.FINISH_COLOR, "Nova": this.NEW_COLOR, "Cancelada": this.CANCELED_COLOR, "Em andamento": this.WAITING_COLOR}
  ARRAY_ICONS =  {"Agrupada": 'group',"Finalizada": 'circle_minus', "Nova": 'Notification', "Cancelada": 'canceled', "Em andamento": 'Play Circle'}
  ARRAY_CLASS =  {"Agrupada":"group","Finalizada": 'finished', "Nova": 'new', "Cancelada": 'cancel', "Em andamento": 'progress'}
  
  logsDynamicTable: DynamicTable = logsTable.logsTable;
  atributtesTable: RequestAttribute[] = [
    {
      param: 'fields',
      value: '',
    },
  ];
  occurrence;
  constructor(    
    public dialogRef: MatDialogRef<ModalLogsComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {

      if(data.screen == 'supervision'){
        this.logsDynamicTable.endpoint = 'supervisions/showLogs' + '/' +  data.occurrence._id;
        this.logsDynamicTable.endpointFilter = 'supervisions/logsFilter' + '/' +  data.occurrence._id;
      }else{
        this.logsDynamicTable.endpoint = 'eventGroups/showLogs' + '/' +  data.occurrence.id
        this.logsDynamicTable.endpointFilter = 'eventGroups/logsFilter' + '/' +  data.occurrence.id
      }

     }

  ngOnInit(): void {
    this.occurrence = this.data.occurrence
  }

  getTagColors(tag){
    let status = '';
    if(tag.status?.includes('Nov') || !tag.status){
      status = "Nova"
    }else{
      status = tag.status
    }
    return {content:status, icon: this.ARRAY_ICONS[status], customColor: this.ARRAY_COLORS[status],class: this.ARRAY_CLASS[status]}

  }

  formatDate(date){
    const formatDate = new Date(date);
    return `${formatDate.toLocaleDateString('pt-br')} às ${formatDate.toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'})}` 
  }

  handleClick(type): void {
    this.dialogRef.close(type);
  }

  getIconColor(icon){
    if(this.occurrence.status == 'Nova'){
      if(icon == 'validation'){
        return '#0072C6'
      }
      return '#949494'
    } else if(this.occurrence.status == "Finalizada"){
      return '#0072C6'
    }else if(this.occurrence.status == "Em andamento" || this.occurrence.status == "Agrupada"){
      if(icon == 'finished'){
        return '#949494'
      }
      return '#0072C6'
    }
  }

  

}
