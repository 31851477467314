<div class="modal-container">
    <div class="head">
      <div class="title">
        {{data.sendData ? 'Agrupar ocorrência' : 'Criar ocorrência'}}
      </div>
      <mat-icon class="icon" (click)="handleClick(false)">close</mat-icon>
    </div>
    <div class="line"></div>

    <div class="content">
        <div class="text">
            Olá usuário! Para criar uma nova ocorrência preencha dados abaixo:
        </div>
        <div class="subtitle">
            INFORMAÇÕES DA OCORRÊNCIA
        </div>

        <form [formGroup]="form" class="data-inputs">
            
            <div class="time-inputs">
                <datapicker-input [datapicker]="datapickerInterface" [disabled]="false" formControlName="datapickerValue"></datapicker-input>
                <mat-form-field [floatLabel]="'always'" appearance="outline" hideRequiredMarker>
                    <mat-label>Hora</mat-label>
                    <input type="time" formControlName="timeValue" placeholder="10:00h" matInput>
                </mat-form-field>
            </div>

            <div style="margin-top: 6px;">
                <select-search-input #selectSubstation [selectInput]="selectInterface[0]" (selectionChange)="onValueEmitterTextAreaInput($event.value,'cod')"></select-search-input>
<!--                 <text-input (valueEmitter)="onValueEmitterTextAreaInput($event, 'cod')" [textInput]="textInterface[0]" (hintOutput)="canSearch = $event"></text-input>
 -->            </div>
            <div><text-input (valueEmitter)="onValueEmitterTextAreaInput($event, 'equipment')" [textInput]="textInterface[1]"></text-input></div>
            <div class="toggle">
                <mat-label>LT DE ALTO RISCO</mat-label>
                <mat-slide-toggle (change)="onToggleChange($event,'highRisk')">
              </mat-slide-toggle>
            </div>

            <div class="toggle">
                <div class="toggle-icon"
                    [ngClass]="{'disabled-div': !canSearch || !this.form.get('cod').valid || !this.form.get('equipment').valid}">
                    <mat-label>Buscar Resultados automáticos (PV e SI)</mat-label>
                    <mat-slide-toggle (change)="onTogglesSearch($event,'search')">
                    </mat-slide-toggle>
                </div>
                <tooltip [tooltip]="{ text: 'Para buscar é necessário que a linha ou instalação seja preenchida em formato de sigla', position: 'top' }">
                    <mat-icon style="margin-top: -3px;"
                        *ngIf="!canSearch || !this.form.get('cod').valid || !this.form.get('equipment').valid" matSuffix
                        svgIcon="notification"></mat-icon>
                </tooltip>
            </div>

            <div class="input">
                <number-suffix-input (valueEmitter)="onValueEmitterTextAreaInput($event, 'pv')"  [textInput]="numberSuffixInterface[0]" ></number-suffix-input>
            </div>
             
            <div class="text-infos">
                <textarea-input [textAreaInput]="textAreaInterface[3]" (valueEmitter)="onValueEmitterTextAreaInput($event, 'sap')"></textarea-input>
                <div class="input"><number-suffix-input (valueEmitter)="onValueEmitterTextAreaInput($event, 'interruptedLoad')" [textInput]="numberSuffixInterface[1]" ></number-suffix-input></div>
                <div class="line"></div>

                <textarea-input [textAreaInput]="textAreaInterface[0]" (valueEmitter)="onValueEmitterTextAreaInput($event, 'description')"></textarea-input>
                <textarea-input [textAreaInput]="textAreaInterface[1]" (valueEmitter)="onValueEmitterTextAreaInput($event, 'breakers')"></textarea-input>
                <textarea-input [textAreaInput]="textAreaInterface[2]" (valueEmitter)="onValueEmitterTextAreaInput($event, 'trips')"></textarea-input>
            </div>

            <div class="line no-margin-top"></div>
 
            <!-- <div class="subtitle">
                AÇÕES EXTRAS <span class="label-obs">(Opcional) </span>
            </div>

             <checkbox [checkbox]="checkBoxInterface[0]" (onChange)="onCheckChange($event,0)"></checkbox>
 -->
        </form>
    </div>

    <div class="action">
      <button-induxtry *ngIf="data?.cancelButton" (action)="handleClick(false)"
          [buttonInduxtry]="{ label: data?.cancelButton?.text, customStyle: 'cancel', showLabel:true, disabled: false }">
      </button-induxtry >
      <button-induxtry *ngIf="data?.validationButton" (action)="confirmCreate(true)" 
          [buttonInduxtry]="{ label: data?.validationButton?.text, customStyle: 'primary', showLabel:true, disabled: disabledButtons() }">
      </button-induxtry>
    </div>
  </div>
  