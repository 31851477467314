import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NumberSuffixInput, TextInput } from '../../../../../utils/models/input.interface';
import { TextInputComponent } from '../text-input/text-input.component';

@Component({
  selector: 'number-suffix-input',
  templateUrl: './number-suffix-input.component.html',
  styleUrls: ['./number-suffix-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextInputComponent,
    },
  ],
})
export class NumberSuffixInputComponent implements OnInit, ControlValueAccessor{

  @Input() textInput: NumberSuffixInput;

  @Output() valueEmitter: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  writeValue(value: any): void {
    this.textInput.value = value;
  }

  onChange: (_: any) => void = () => { 
  }

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnChanges(change){
    console.log("valor = ",change)
  }

  ngOnInit(): void {
    
  }

  setValue(value) {
    //this.textInput.value = value
    this.onChange(this.textInput.value);
    this.valueEmitter.emit(Number(value.replaceAll('.', '').replaceAll(',', '.')));
  }
}
