import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import icons from '../utils/constants/layout/icons.json';
import { SenderAlertMessages } from 'src/utils/mensagens/sender-alert-messages';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers:[SenderAlertMessages]
})

export class AppComponent {

  iconsArray = icons.icons;
  otherIcons = icons.icons_menor;
  feedbackArray = icons.feedback;
  loadingArray = icons.loading;

  constructor(private readonly iconregistry: MatIconRegistry, private readonly sanitizer: DomSanitizer, private readonly alertMessages: SenderAlertMessages) {
    this.iconsArray.forEach(icon => {
      this.iconregistry.addSvgIcon(icon.icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.path}`));
    });

    this.otherIcons.forEach(icon => {
      this.iconregistry.addSvgIcon(icon.icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.path}`));
    });

    this.feedbackArray.forEach(icon => {
      this.iconregistry.addSvgIcon(icon.icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.path}`));
    });

    this.loadingArray.forEach(icon => {
      this.iconregistry.addSvgIcon(icon.icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.path}`));
    });

    alertMessages.getMessages()
  }

}
