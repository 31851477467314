<div class="list-container">
    <div class="content-list" *ngFor="let item of list.items; index as i">
        <div class="marker">
            <span
                *ngIf="list.marker === 'number'" 
                class="number-mark"> 
                {{ i+1 }}
            </span>

            <div 
                *ngIf="list.marker === 'circle' || list.marker === 'circle-empty'" 
                class="circle-mark"
                [ngClass]="{'empty': list.marker === 'circle-empty'}"></div>

            <div 
                *ngIf="list.marker === 'square' || list.marker === 'square-empty'" 
                class="square-mark"
                [ngClass]="{'empty': list.marker === 'square-empty'}"></div>

            <mat-icon
                *ngIf="list.marker === 'star'"
                class="icon-mark"
                svgIcon="Favourite"
              ></mat-icon>

            <mat-icon
                *ngIf="list.marker === 'check'"
                class="icon-mark"
                svgIcon="Mobile Accept"
              ></mat-icon>
        </div>
        <span class="itemize">
            {{ item }}
        </span>
    </div>
</div>