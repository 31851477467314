<!-- <mat-form-field class="mat-form-field" [floatLabel]="'always'" appearance="outline">
    <mat-label class="mat-label-date">
        <span class="mat-label-date">{{ textInput.label }} <mat-icon [style.margin-left]="'2px'" *ngIf="textInput.disabled">lock</mat-icon></span>
        <span class="label-obs">{{textInput.obs}}
        </span>
        
    </mat-label>
    <input
        matInput
        [placeholder]="textInput.placeholder"
        type="text"
        [(ngModel)]="textInput.value"
        [disabled]="textInput.disabled"
        [readonly]="textInput.readonly"
        (input)="setValue()"
    >
</mat-form-field> -->

<mat-form-field class="mat-form-field" [floatLabel]="'always'" appearance="outline">

    <mat-label class="mat-label-date">
        <span class="mat-label-date">{{ textInput.label }} <mat-icon [style.margin-left]="'2px'" *ngIf="textInput.disabled">lock</mat-icon></span>
        <span> {{textInput.obs}}
        </span>
    </mat-label>
    <input 
        #inputValue 
        [disabled]="textInput.disabled"
        [placeholder]="textInput.placeholder"
        thousandSeparator="." 
        decimalMarker="," 
        separatorLimit="1000000000"
        type="text" maxlength="15" 
        oninput="this.value=this.value.slice(0,this.maxLength)" matInput (input)="setValue(inputValue.value)"
        [value]="textInput.value"
         />
    <span *ngIf="textInput.suffix" class="suffix" matSuffix>{{textInput.suffix}}&nbsp;</span>
</mat-form-field>

<!--        mask="separator.2"  
-->