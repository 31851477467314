<mat-form-field [floatLabel]="'always'" appearance="outline" hideRequiredMarker>
    <mat-label *ngIf="!datapicker.disabled">
        {{ datapicker.label }}
        <span class="label-obs">{{datapicker.obs}}</span>
        <mat-icon *ngIf="datapicker.disabled">lock</mat-icon>
    </mat-label>

    <mat-label *ngIf="datapicker.disabled" > <span class="mat-label-date">{{ datapicker.label }} <mat-icon [style.margin-left]="'2px'" *ngIf="datapicker.disabled">lock</mat-icon></span> </mat-label>

    <input
        matInput
        [placeholder]="datapicker.placeholder"
        [matDatepicker]="picker"
        [disabled]="datapicker.disabled"
        [(ngModel)]="datapicker.date"
        (dateChange)="setNewDate()"
        (input)="sendEmpty()"
        [required]="datapicker.required"
    >
    <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon class="calendar-icon" matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
