<div class="popover-container"
     (mouseenter)="menuTrigger.openMenu()"
     (mouseleave)="menuTrigger.closeMenu()">
  <div class="popover-text"
      [matMenuTriggerFor]="menu"
       #menuTrigger="matMenuTrigger">{{formatText()}}</div>
  <mat-menu #menu="matMenu"
            [xPosition]="xPosition"
            class="mat-menu-popover"
            [hasBackdrop]="false">
    <div mat-menu-item
         [disableRipple]="true"
         disabled
         (click)="$event.stopPropagation()">
      <ng-content></ng-content>
    </div>
  </mat-menu>
</div>
