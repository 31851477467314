<div class="buttons">
  <button
    class="button-generic"
    [disabled]="buttonInduxtry.disabled || forceDisabled "
    [class.primary]="buttonInduxtry.customStyle === 'primary'"
    [class.secondary]="buttonInduxtry.customStyle === 'secondary'"
    [class.tertiary]="buttonInduxtry.customStyle === 'tertiary'"
    [class.cancel]="buttonInduxtry.customStyle === 'cancel'"
    [ngClass]="{ 'disabled': buttonInduxtry.disabled || forceDisabled }"
    [style.width]="buttonInduxtry.width"
    (click)="onButtonClicked($event)"
  >
    <div class="alert"><div *ngIf="buttonInduxtry.alert" class="dot"></div></div>
    <mat-icon *ngIf="buttonInduxtry.icon" class="button-icon" [svgIcon]="buttonInduxtry.icon"></mat-icon>
  </button>
</div>
