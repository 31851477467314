import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment';

const LIMIT_HOUR = 23;
const LIMIT_MINUTE = 59;
@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' }
  ],
})
export class TimePickerComponent implements OnInit {

  constructor() { }

  @Input() stepHour = 1;
  @Input() stepMinute = 1;


  @Input() moment = moment();
  _hour: string;
  _minute: string;

  _oldValue: string;

  @Output() changeTime: EventEmitter<any> = new EventEmitter<any>();
  
  ngOnInit() {
    this._hour = this.moment.format('HH');
    this._minute = this.moment.format('mm');
  }
  
  increaseHours() {
    this.moment.add(this.stepHour, 'hours');
    this._hour = this.moment.format('HH');

    this.changeTime.emit(this.moment);
  }

  decreaseHours() {
    this.moment.subtract(this.stepHour, 'hours');
    this._hour = this.moment.format('HH');
    this.changeTime.emit(this.moment);
  }

  increaseMinutes() {
    this.moment.add(this.stepMinute, 'minutes');
    this._minute = this.moment.format('mm');
    this._hour = this.moment.format('HH');
    this.changeTime.emit(this.moment);
  }

  decreaseMinutes() {
    this.moment.subtract(this.stepMinute, 'minutes');
    this._minute = this.moment.format('mm');
    this._hour = this.moment.format('HH');
    this.changeTime.emit(this.moment);
  }

  setNewHour() {
    if(parseInt(this._hour) > LIMIT_HOUR) {
      this.moment.set({ hour: parseInt(this._oldValue)});
    } else {
      this.moment.set({ hour: parseInt(this._hour)});
    }
    this._hour = this.moment.format('HH');
    this.changeTime.emit(this.moment);
  }

  setNewMinute() {
    if (parseInt(this._minute) > LIMIT_MINUTE) {
      this.moment.set({ minute: parseInt(this._oldValue)});  
    } else {
      this.moment.set({ minute: parseInt(this._minute)});
    }
    this._minute = this.moment.format('mm');
    this.changeTime.emit(this.moment);
  }

  setOldValue(type) {
    if(type === 'minute') {
      this._oldValue = this._minute;
    } else {
      this._oldValue = this._hour;
    }
  }
}
