<div class="card-top" *ngIf="unavaibilitySystem">
    <div class="content">
    <div class="left">
        <div class="title">
            <mat-icon class="icon" svgIcon="error"></mat-icon>
            Indisponibilidade na comunicação com o {{unavaibilitySystem.name}}
        </div>
        <div class="text">
            Código 1001. Não foi possível estabelecer conexão com {{unavaibilitySystem.name}} para coleta de dados atualizados das
            ocorrências.
        </div>
    </div>

    <div class="right"><span class="simple-button" (click)="action()">MAIS DETALHES</span></div>
</div>
</div>