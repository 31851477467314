import { Component, Input, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MultiSelectInput } from '../../../../../utils/models/input.interface';

const MAX_NUMBER = 3;
@Component({
  selector: 'multiselect-input',
  templateUrl: './multiselect-input.component.html',
  styleUrls: ['./multiselect-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultiselectInputComponent,
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'overlay-container' }
    }
  ],
})
export class MultiselectInputComponent implements OnInit, ControlValueAccessor {
  @ViewChild('multipleSelectInput') multipleSelectInput;

  @Input() multiSelectInput: MultiSelectInput;

  private _showMax = MAX_NUMBER;
  @Input()
  get max(): number {
    return this._showMax;
  }
  set max(max: number) {
    this._showMax = max;
  }

  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  writeValue(value: any): void {
    this.multiSelectInput.selectedItem = value;
  }

  onChange = (value: any) => {
    return value;
  };

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(onChange: (value: any) => any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
  }

  setNewValue(option) {
    this.onChange(option.value);
    this.selectionChange.emit(option.value);
  }

  removeOptionSelected(ev: Event, option) {
    if (!this.multiSelectInput.disabled) {
      ev.stopPropagation();
      this.multipleSelectInput.options.forEach(opt => {
        if (opt.value.value === option.value) {
          opt.deselect();
        }
      });
    }

  }
}
