import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InternalService } from 'src/app/internal/services/internal.service';
import { SwalComponent } from '../swal/swal.component';
import { RequestParams } from 'src/utils/models/http.interface';

@Component({
  selector: 'app-modal-add-exception',
  templateUrl: './modal-add-exception.component.html',
  styleUrls: ['./modal-add-exception.component.scss']
})
export class ModalAddExceptionComponent implements OnInit {

  optionsCod = [];
  requestParams: RequestParams = {
    skip: 0,
    sort: '',
    limit: 10,
    page: 1,
  }
  form = this.formBuilder.group({
    value: new FormControl('',[Validators.required]),
    substation: new FormControl('',[Validators.required]),
  })

  selectInterface = [
    { selectedItem: '', label: 'Instalação (Sigla)', placeholder: 'Selecione a instalação', options: this.optionsCod, labelShow: 'viewValue' },
    ]
    numberSuffixInterface = {value: '', label: 'Condição da perda de supervisão (min)', placeholder: 'Insira um valor em minutos', disabled: false,  suffix: ""}

  constructor(
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ModalAddExceptionComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly internalService: InternalService,
    public dialog: MatDialog,
    private readonly toastr: ToastrService) { }

  ngOnInit(): void {
    this.internalService.listSubstations().subscribe(response=>{
      this.selectInterface[0].options = response.map(substation => {return {value: substation, viewValue: substation}})
    })
   }

  onValueEmitterSelectInput(field, value){
    this.form.get(field).setValue(value.value)
  }
  onValueNumberInput(field,value){
    this.form.get(field).setValue(value)
  }

  handleClick(type: boolean): void {
    if(type){
      this.internalService.getSupervisionValue(this.requestParams,[{param: 'substation', value: this.form.value.substation}]).subscribe((response:any)=>{
        this.dialog.open(SwalComponent, {
          disableClose: true,
          width: '352px',
          height: '200px',
          panelClass: 'custom-modalbox-swal',
          //maxHeight: '941px',
          data: {
            type: 'notification',
            cancelButton: {text: 'CANCELAR'},
            validationButton: {text: 'CONTINUAR'},
            title: 'Atualizar exceção',
            text: `Atenção! Já existe uma exceção cadastrada para essa instalação, ao inserir esses novos dados você irá atualizar o valor e os dados atuais
            serão sobrescritos. Deseja continuar?`
          }
        }).afterClosed().subscribe(res=>{
          if(res){
            this.internalService.updateParameter({supervision: this.form.value.value}, [{param: 'substation', value: this.form.value.substation}]).subscribe(el=>{
              this.toastr.show('Salvo com sucesso', 'Sucesso', { timeOut: 500 , messageClass: 'success'});
            })
            this.dialogRef.close(type); 
          }
        })

      },error=>{
        console.log(error)
        if(error.status == 404){
          this.internalService.updateParameter({supervision: this.form.value.value}, [{param: 'substation', value: this.form.value.substation}]).subscribe(el=>{
            this.toastr.show('Salvo com sucesso', 'Sucesso', { timeOut: 500 , messageClass: 'success'});
          })
          this.dialogRef.close(type); 
        }
      })



    }else{
      this.dialogRef.close(type); 
    }
  }

  disabledButtons(){
    return !this.form.valid
  }

}
