import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Http
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './service/auth/auth.interceptor';

// Language
import { registerLocaleData } from '@angular/common';
import localept from '@angular/common/locales/pt';
registerLocaleData(localept, 'pt');

// Routing
import { AppRoutingModule } from './app-routing.module';

// Shared Module
import { SharedModule } from './shared/shared.module';

// Component
import { AppComponent } from './app.component';
import { AuthService } from './service/auth/auth.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { MsalConfigDynamicModule } from './service/config-msal/msal-config-dynamic.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    NgbModule,
    MsalConfigDynamicModule.forRoot('assets/config/config.json')
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS,
      useFactory: function(authService: AuthService, router: Router, dialog: MatDialog) {
        return new AuthInterceptor(authService, router, dialog);
      },
      multi: true,
      deps: [AuthService, Router, MatDialog]
    },
    AuthService,
    MsalService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
