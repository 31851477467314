<div class="input-container">
    <input #checkboxInput type="checkbox" [(ngModel)]="checkbox.checked" class="checkbox-opacity" (click)="onClick()">
    <button
        class="checkbox"
        [ngClass]="{
            'checked': checkbox.checked
        }"
        (click)="checkboxInput.click()"
        [disabled]="checkbox.disabled"
    ></button>
    <span class="label-input" *ngIf="checkbox.label">{{ checkbox.label }}</span>
</div>
