import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'swal',
  templateUrl: './swal.component.html',
  styleUrls: ['./swal.component.scss']
})
export class SwalComponent {

  constructor(public dialogRef: MatDialogRef<SwalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  handleClick(type: boolean): void {
    this.dialogRef.close(type);
  }

}
