<div class="empty-state-container">
    <mat-icon
        class="icon-state"
        [ngClass]="{'_condensed': condensed}"
        *ngIf="icon"
        [svgIcon]="icon"
    ></mat-icon>
    <div 
        class="description-container"
        [ngClass]="{'_condensed': condensed}">
        <span class="title">
            {{ title }}
        </span>
        <span class="description">
            {{ description }}
        </span>
    </div>
</div>