import { AfterContentInit, Component, ContentChildren, EventEmitter, Output, QueryList, ViewChild } from '@angular/core';
import { DividerComponent } from '../divider/divider.component';
import { TabInduxtryComponent } from './tab-induxtry/tab-induxtry.component';

@Component({
  selector: 'tabs-induxtry',
  templateUrl: './tabs-induxtry.component.html',
  styleUrls: ['./tabs-induxtry.component.scss']
})
export class TabsInduxtryComponent implements AfterContentInit {
  
  @ContentChildren(TabInduxtryComponent) tabs: QueryList<TabInduxtryComponent>;
  @ViewChild('divider') divider: DividerComponent;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  ngAfterContentInit(): void {
    const mockTabs = [
      {
        active: true,
        disabled: false,
        title: "Exemplo Tab 1"
      },
      {
        active: false,
        disabled: false,
        title: "Exemplo Tab 2"
      },
      {
        active: false,
        disabled: false,
        title: "Exemplo Tab 3"
      }
     
    ]
    let listMock = Object.assign(new QueryList(), {
      _results: mockTabs,
      first:mockTabs[0],
      last:mockTabs[2],
      dirty:false,
      _changesDetected:true,
      _emitDistinctChangesOnly: true,
      length:mockTabs.length
    
    }) as QueryList<TabInduxtryComponent>;

    const activeTabs = this.tabs.filter(tab => tab.active);
    if(activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    } else {
      this.validActiveTabs();
      this.onChange.emit(activeTabs[0]);
    }
  }

  selectTab(tab) {
    this.tabs.toArray().forEach(tabSelect => (tabSelect.active = false));
    tab.active = true;
    this.onChange.emit(tab);
  }

  validActiveTabs() {
    const activeTabs = this.tabs.filter(tab => tab.active);

    if(activeTabs.length > 1) {
      activeTabs.slice(1).forEach(tab => {
        tab.active = false;
      })
    }
  }
}
