<div class="select">
    <mat-form-field [floatLabel]="'always'" appearance="outline">
        <mat-label>
            {{ selectInput.label }}
            <span class="label-obs">{{selectInput.obs}}</span>
        </mat-label>
        <mat-select
            style="font-size:12px; font-weight:400"
            [placeholder]="selectInput.placeholder"
            [disabled]="selectInput.disabled"
            [(ngModel)]="selectInput.value"
            (selectionChange)="setNewValue()"
        >
          <mat-option
                *ngFor="let option of selectInput.options; index as i"
                [value]="option?.value"
            >
            <span>
                {{option?.viewValue}}
            </span>
          </mat-option>
        </mat-select>
    </mat-form-field>
</div>
