<div class="content contentPages" [ngStyle]="{'box-shadow': hasBoxShadow ? '0px 1px 3px rgba(0, 0, 0, 0.12)' :'none'}">
  <div id="left"></div>
  <div id="center" style="display: flex; justify-content: center;">
    <div class="c" style="border: 1px solid #D1D1D1;margin-right: 2px;" *ngIf="pages > 1" (click)="changePag('-')">
      <mat-icon style="position: relative; left: -2px;" class="arrowButtonIcon">chevron_left</mat-icon>
    </div>

    <div style="display: flex;" *ngIf="pages === 1">
      <div class="c" [ngStyle]="styleObject(1)">1</div>
    </div>

    <div style="display: flex;" *ngIf="pages === 2">
      <div class="c" (click)="switchToThisPage(1)" [ngStyle]="styleObject(1)">1</div>
      <div class="c" (click)="switchToThisPage(2)" [ngStyle]="styleObject(2)">2</div>
    </div>

    <div style="display: flex;" *ngIf="pages === 3">
      <div class="c" (click)="switchToThisPage(1)" [ngStyle]="styleObject(1)">1</div>
      <div class="c" (click)="switchToThisPage(2)" [ngStyle]="styleObject(2)">2</div>
      <div class="c" (click)="switchToThisPage(3)" [ngStyle]="styleObject(3)">3</div>
    </div>

    <div style="display: flex;" *ngIf="pages === 4">
      <div class="c" (click)="switchToThisPage(1)" [ngStyle]="styleObject(1)">1</div>
      <div class="c" (click)="switchToThisPage(2)" [ngStyle]="styleObject(2)">2</div>
      <div class="c" (click)="switchToThisPage(3)" [ngStyle]="styleObject(3)">3</div>
      <div class="c" (click)="switchToThisPage(4)" [ngStyle]="styleObject(4)">4</div>
    </div>

    <div style="display: flex;" *ngIf="pages > 4 && selectedPage === 1">
      <div class="c" (click)="switchToThisPage(selectedPage)" [ngStyle]="styleObject(selectedPage)">{{selectedPage}}</div>
      <div class="c" (click)="switchToThisPage(selectedPage+1)" [ngStyle]="styleObject(selectedPage+1)">{{selectedPage + 1}}</div>
      <div class="c" (click)="switchToThisPage(selectedPage+2)" [ngStyle]="styleObject(selectedPage+2)">{{selectedPage + 2}}</div>
      <div class="c" [ngStyle]="styleObject(0)">...</div>
      <div class="c" (click)="switchToThisPage(pages)" [ngStyle]="styleObject(0)">{{pages}}</div>
    </div>

    <div style="display: flex;" *ngIf="pages > 4 && selectedPage > 1 && selectedPage !== pages && selectedPage === pages-2">
      <div class="c" (click)="switchToThisPage(1)" [ngStyle]="styleObject(1)">{{1}}</div>
      <div class="c" [ngStyle]="styleObject(0)">...</div>
      <div class="c" (click)="switchToThisPage(selectedPage)" [ngStyle]="styleObject(selectedPage)">{{selectedPage}}</div>
      <div class="c" (click)="switchToThisPage(selectedPage+1)" [ngStyle]="styleObject(selectedPage+1)">{{selectedPage+1}}</div>
      <div class="c" (click)="switchToThisPage(selectedPage+2)" [ngStyle]="styleObject(selectedPage+2)">{{selectedPage+2}}</div>
    </div>

    <div style="display: flex;" *ngIf="pages > 4 && selectedPage > 1 && selectedPage !== pages && selectedPage === pages-1">
      <div class="c" (click)="switchToThisPage(1)" [ngStyle]="styleObject(1)">{{1}}</div>
      <div class="c" [ngStyle]="styleObject(0)">...</div>
      <div class="c" (click)="switchToThisPage(selectedPage-1)" [ngStyle]="styleObject(selectedPage-1)">{{selectedPage-1}}</div>
      <div class="c" (click)="switchToThisPage(selectedPage)" [ngStyle]="styleObject(selectedPage)">{{selectedPage}}</div>
      <div class="c" (click)="switchToThisPage(selectedPage+1)" [ngStyle]="styleObject(selectedPage+1)">{{selectedPage+1}}</div>
    </div>

    <div style="display: flex;" *ngIf="pages > 4 && selectedPage > 1 && selectedPage !== pages && selectedPage !== pages-2 && selectedPage !== pages-1 ">
      <div class="c" (click)="switchToThisPage(selectedPage-1)" [ngStyle]="styleObject(selectedPage-1)">{{selectedPage-1}}</div>
      <div class="c" (click)="switchToThisPage(selectedPage)" [ngStyle]="styleObject(selectedPage)">{{selectedPage}}</div>
      <div class="c" (click)="switchToThisPage(selectedPage+1)" [ngStyle]="styleObject(selectedPage+1)">{{selectedPage + 1}}</div>
      <div class="c" [ngStyle]="styleObject(0)">...</div>
      <div class="c" (click)="switchToThisPage(pages)" [ngStyle]="styleObject(0)">{{pages}}</div>
    </div>

    <div style="display: flex;" *ngIf="pages > 4 && selectedPage === pages && selectedPage !== pages-2">
      <div class="c" (click)="switchToThisPage(1)" [ngStyle]="styleObject(selectedPage-1)">1</div>
      <div class="c" (click)="switchToThisPage(0)" [ngStyle]="styleObject(0)">...</div>
      <div class="c" (click)="switchToThisPage(selectedPage-2)"[ngStyle]="styleObject(selectedPage-2)">{{selectedPage -2}}</div>
      <div class="c" (click)="switchToThisPage(selectedPage-1)"[ngStyle]="styleObject(selectedPage-1)">{{selectedPage -1}}</div>
      <div class="c" (click)="switchToThisPage(selectedPage)" [ngStyle]="styleObject(selectedPage)">{{selectedPage}}</div>
    </div>

    <div class="c" style="border: 1px solid #D1D1D1;margin-left: 2px;" *ngIf="pages>1" (click)="changePag('+')">
      <mat-icon class="arrowButtonIcon" style="position: relative; left: -1px;">chevron_right</mat-icon>
    </div>
  </div>
  <div style="display: flex; align-items: center;" id="right" class="right">
    <div>Exibindo</div>
      <mat-form-field appearance="outline"
                      style="height: 20px; width: 110px;"
                      [floatLabel]="'always'"
                      class="matformField">
        <mat-select [formControl]="select">
          <mat-option *ngFor="let item of pageSizeOptions, index as i" [style.display]="i == pageSizeOptions.length-1 ? 'none' : 'flex'" (click)="changeManyItens(item)" [value]="item">{{item}} itens</mat-option>
        </mat-select>
      </mat-form-field>
    <div>de <strong>{{length}}</strong> resultados</div>
  </div>
</div>
