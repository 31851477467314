import { Component, Input } from '@angular/core';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {

  @Input() icon: string | null;
  @Input() title: string;
  @Input() description: string | null;
  @Input() condensed: boolean;

}
