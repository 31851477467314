<mat-form-field [floatLabel]="'always'" appearance="outline">
    <mat-label > <span class="mat-label-date">{{ textAreaInput.label }} 
        <mat-icon [style.margin-left]="'6px'" *ngIf="!textAreaInput.disabled && textAreaInput.hasEditableIcon">edit</mat-icon>
        <mat-icon [style.margin-left]="'2px'" *ngIf="textAreaInput.disabled">lock</mat-icon>
    </span> 
      
    </mat-label>

    <textarea
        matInput
        [rows]="textAreaInput.rows"
        [placeholder]="textAreaInput.placeholder"
        type="text"
        [(ngModel)]="textAreaInput.value"
        [disabled]="textAreaInput.disabled"
        (input)="setNewValue()"
    ></textarea>

</mat-form-field>
