import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ChipsInterface } from '../../../../../utils/models/miscelanea.interface';

@Component({
  selector: 'chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent {

  @Input() chips: ChipsInterface;

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(private readonly element: ElementRef) {}

  closeChip() {
    this.onClose.emit(this.element);
    this.element.nativeElement.remove();
  }
}
