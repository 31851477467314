<div class="buttons">
  <button
    class="button-generic"
    [disabled]="buttonInduxtry.disabled"
    [class.primary]="buttonInduxtry.customStyle === 'primary'"
    [class.secondary]="buttonInduxtry.customStyle === 'secondary'"
    [class.tertiary]="buttonInduxtry.customStyle === 'tertiary'"
    [class.cancel]="buttonInduxtry.customStyle === 'cancel'"
    [ngClass]="{ 'disabled': buttonInduxtry.disabled }"
    [style.width]="buttonInduxtry.width"
    (click)="onButtonClicked($event)"
  >
    <mat-icon *ngIf="buttonInduxtry.icon" class="button-icon" [svgIcon]="buttonInduxtry.icon"></mat-icon>
    <span class="text"  *ngIf="buttonInduxtry.showLabel" [ngClass]="{ 'label': buttonInduxtry.icon, 'padding-off': buttonInduxtry.customStyle == 'cancel' }">{{ buttonInduxtry.label }}</span>
  </button>
</div>
