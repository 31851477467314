import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextInput } from '../../../../../utils/models/input.interface';

@Component({
  selector: 'text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextInputComponent,
    },
  ],
})
export class TextInputComponent implements OnInit, ControlValueAccessor{

  @Input() textInput: TextInput;
  @Output() valueEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() hintOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  hasHint = false;

  constructor() { }

  writeValue(value: any): void {
    this.textInput.value = value;
  }

  onChange: (_: any) => void = () => { }

  onTouched = (value: unknown) => {
    return value;
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
  }
  
  updateHint(){
    this.hasHint = this.textInput.value !== "" 
    && !!!this.textInput.regexHint?.exec(this.textInput.value)
    this.hintOutput.emit(!this.hasHint)
  } 

  setValue() {
    this.onChange(this.textInput.value);
    this.valueEmitter.emit(this.textInput.value);
    if(this.textInput.hint) this.updateHint();
  }
}
