<form [formGroup]="form">
    <mat-form-field appearance="outline" class="field-alternative" [floatLabel]="'always'">
        <mat-label>
            {{ selectInput.label }}
            <span class="label-obs">{{selectInput.obs}}</span>
        </mat-label>
        
        <select-infinite-scroll-search
        class="select-infinite"
        #selectInfinite
        formControlName="filter"
        [labelShow]="selectInput.labelShow"
        [internalFilter]="true"
        [options]="selectInput.options"
        [multiple]="false"
        [isTableFilter]="false"
        [hasClearOption]="true"
        (filterEvent)="onValueEmitterSelectInput($event)"
        (selectionChange)="onValueEmitterSelectInput($event)"
        (clearFilter)="clearFilter()"
        [placeholder]="selectInput.placeholder">
        </select-infinite-scroll-search>
    </mat-form-field>
</form>