<div
  class="table-container"
  [ngClass]="{ loading: dataSource?.loading$ | async }"
>
  <table
    mat-table
    class="table-content"
    aria-label="Elements"
    [dataSource]="dataSource"
    [trackBy]="trackArray"
  >
    <ng-container
      [matColumnDef]="column?.attribute"
      [sticky]="column.sticky"
      *ngFor="
        let column of _dynamicTable.displayedColumns;
        let index = index;
        trackBy: trackArray
      "
    >
      <th mat-header-cell class="header-actions-container" *matHeaderCellDef>
        <span
          class="title-text"
          style="white-space: break-spaces"
          *ngIf="column?.attribute === 'abc'"
        >
          {{ column?.title }}
        </span>

        <span
          class="title-text"
          *ngIf="column?.attribute != 'abc' && column?.type !== 'action'"
        >
          {{ column?.title }}
          <mat-icon style="position: relative;top: 2px; width: 14px !important;">lock</mat-icon>
        </span>

        <span class="subtitleIconLook">
          {{ column?.subtitle?.name }}
          <mat-icon class="iconlook" *ngIf="column?.subtitle?.look"
            >lock</mat-icon
          >
        </span>

        <div class="sort-container" *ngIf="column?.hasSort">
          <mat-icon
            aria-hidden="false"
            class="sort-icon"
            *ngIf="column?.hasSort"
            (click)="handleSort(column.attribute)"
            [ngClass]="{
              activate:
                column?.attribute === this.dynamicTable?.requestParams?.sort
            }"
          >
            {{
              this._dynamicTable?.requestParams?.order === "desc"
                ? "arrow_drop_down"
                : "arrow_drop_up"
            }}
          </mat-icon>
        </div>

        <div class="pin-container" *ngIf="column?.stickyView">
          <mat-icon
            aria-hidden="false"
            class="pin-icon"
            [ngClass]="{ colorIcon: column.sticky }"
            (click)="handlePin(column)"
          >
            push_pin
          </mat-icon>
        </div>

        <div class="filter-container" *ngIf="column?.hasFilter">
          <ng-container>
            <mat-icon
              aria-hidden="false"
              class="filter-icon"
              *ngIf="column?.hasFilter"
              [ngClass]="{
                activate:
                  filterTable?.filterForm?.controls['filter']?.value?.length > 0
              }"
              (click)="filterTable?.multiSelect.multiSelect.toggle()"
            >
              filter_alt
            </mat-icon>
            <filter-table
              #filterTable
              [column]="column"
              [options]="
                filterOptions[column.attribute]
                  ? filterOptions[column.attribute]
                  : []
              "
              (closed)="handleFilter($event, column.attribute)"
              (emitReload)="handleFilter($event, column.attribute)"
            >
            </filter-table>
          </ng-container>
        </div>
      </th>

      <!-- Default Cells-->
      <td mat-cell *matCellDef="let element; let e = index">
        <ng-container
          *ngIf="
            !column?.hasInput &&
            !column?.hasSubTable &&
            !column?.isIconColumn
          "
        >
          <div>
            {{
              column?.type === "number"
                ? emptyText(element[column?.attribute] | number: "1.0-2")
                : column?.type === "text"
                ? emptyText(element[column?.attribute])
                : column?.type === "date"
                ? emptyText(element[column?.attribute] | date: "dd/MM/yyyy")
                : column?.type === "date-time"
                ? (element[column?.attribute] | date: "dd/MM/yyyy 'às' HH:mm")
                : column?.type === "array"
                ? element[column?.attribute]
                : column?.type === "default"
                ? "Default"
                : ""
            }}
          </div>
          <div
            *ngIf="column?.attribute === 'action'"
            class="buttons buttons-action"
          >

          <tooltip [tooltip]="{ text: 'Ver detalhes', position: 'bottom' }" *ngIf="column?.buttons.view">
            <button-induxtry
              [buttonInduxtry]="{ label: '', showLabel: false, customStyle: 'tertiary', icon: 'View' }"
              (action)="handleView(element)"
            >
            </button-induxtry >
          </tooltip>

            <tooltip [tooltip]="{ text: 'Duplicar', position: 'bottom' }" *ngIf="column?.buttons.duplicar">
              <button-induxtry
                [buttonInduxtry]="{ label: '', showLabel: false, customStyle: 'tertiary', icon: 'Duplicar' }"
                (action)="handleDuplicate(element)"
              >
              </button-induxtry >
            </tooltip>

            <tooltip [tooltip]="{ text: 'Editar', position: 'bottom' }" *ngIf="column?.buttons.edit">
              <button-induxtry
                [buttonInduxtry]="{ label: '', showLabel: false, customStyle: 'tertiary', icon: 'Edit' }"
                (action)="handleEdit(element)"
              >
              </button-induxtry >
            </tooltip>

            <tooltip [tooltip]="{ text: 'Excluir', position: 'bottom' }" *ngIf="column?.buttons.delete">
              <button-induxtry
                [buttonInduxtry]="{ label: '', showLabel: false, customStyle: 'secondary', icon: 'Delete' }"
                (action)="handleDelete(element)"
              >
              </button-induxtry >
            </tooltip>

          </div>
        </ng-container>

        <ng-container
          *ngIf="column?.hasInput"
        >
          <mat-form-field
            floatLabel="never"
            appearance="none"
            [ngClass]="{
              text: column?.hasInput === 'text',
              date: column?.hasInput === 'date'
            }"
          >
            <div class="ngx-teste">
              <mat-form-field
                *ngIf="column?.type === 'date-time'"
                [floatLabel]="'always'"
                appearance="none"
                style="width: 200px"
              >
                <input
                  style="color: #000000"
                  matInput
                  readonly
                  [ngxMatDatetimePicker]="picker"
                  [(ngModel)]="element[column?.attribute]"
                  (ngModelChange)="setEdit(e)"
                />
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon
                    matDatepickerToggleIcon
                    svgIcon="calendar"
                  ></mat-icon>
                </mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
              </mat-form-field>
            </div>

            <mat-form-field
              *ngIf="column?.type === 'date'"
              [floatLabel]="'always'"
              appearance="none"
              style="width: 200px"
            >
              <input
                style="color: #000000"
                matInput
                readonly
                [(ngModel)]="element[column?.attribute]"
                [matDatepicker]="picker"
                (ngModelChange)="setEdit(e)"
              />
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <input
              style="color: #000000"
              matInput
              autocomplete="off"
              *ngIf="column?.type === 'number'"
              type="{{ column?.type }}"
              [(ngModel)]="element[column?.attribute]"
              [maxlength]="'7'"
              currencyMask
              id="[{{ e }}][{{ index }}]"
              step=".000001"
              (keyup.enter)="setRowCol(e, index)"
              (ngModelChange)="setEdit(e)"
              (keypress)="decimalFilter($event)"
            />

            <mat-form-field
              [floatLabel]="'always'"
              appearance="none"
              *ngIf="column?.type === 'number-default'"
            >
              <input
                style="color: #000000"
                matInput
                autocomplete="off"
                type="number"
                [(ngModel)]="element[column?.attribute]"
                [maxlength]="'7'"
                currencyMask
                id="[{{ e }}][{{ index }}]"
                step=".000001"
                (keypress)="decimalFilter($event)"
                (keyup.enter)="setRowCol(e, index)"
                (ngModelChange)="setEdit(e)"
              />
            </mat-form-field>

            <input
              style="color: #000000"
              matInput
              autocomplete="off"
              *ngIf="column?.type === 'text'"
              type="text"
              [(ngModel)]="element[column?.attribute]"
              [maxlength]="'30'"
              currencyMask
              id="[{{ e }}][{{ index }}]"
              (keyup.enter)="setRowCol(e, index)"
              (ngModelChange)="setEdit(e)"
              [class.steel]=""
            />
          </mat-form-field>
        </ng-container>

        <ng-container
        *ngIf="column?.isIconColumn"
        >
        <mat-icon class="icon-success" [svgIcon]="element[column?.attribute] ? 'success' : 'error'"></mat-icon>
        </ng-container>
      </td
    ></ng-container>

    <!-- FOOTER FICA AQUI-->
    <tr mat-header-row *matHeaderRowDef="_dynamicTable?.columns"></tr>
    <tr
      mat-row
      *matRowDef="let row = index; columns: _dynamicTable?.columns"
    ></tr>
  </table>

  <div *ngIf="data?.length === 0 && (dataSource?.loading$ | async) === false">
    <img
      style="margin: auto; display: block"
      height="300"
      width="300"
      src="../../../../assets/images/table.svg"
      alt=""
    />
  </div>
</div>

<app-page-select
  #pageSelect
  *ngIf="hasPaginate && (dataSource?.count$ | async) > 0"
  [length]="dataSource?.count$ | async"
  [pageSize]="manyItens"
  [selectedPage]="_dynamicTable.requestParams.page"
  [pageSizeOptions]="[10, 20, 30, 40, 50]"
  [hasBoxShadow]="false"
  (emitPageSelected)="changePage($event)"
  (itensOutput)="changeManyItens($event)"
>
</app-page-select>
