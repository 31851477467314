import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonInduxtry } from '../../../../../utils/models/input.interface';

@Component({
  selector: 'button-induxtry',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() buttonInduxtry: ButtonInduxtry;
  @Output() action: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (this.buttonInduxtry.customStyle === undefined || this.buttonInduxtry.customStyle === null) {
      this.buttonInduxtry.customStyle = 'primary';
    }

    if(this.buttonInduxtry.showLabel === undefined || this.buttonInduxtry.showLabel === null ) {
      this.buttonInduxtry.showLabel = true;
    }
  }

  ngOnChanges(change){
  }

  onButtonClicked(params: any) {
    this.action.emit([params]);
  }
}
