import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  NUMBER_401 = 401;
  NUMBER_403 = 403;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly dialog: MatDialog
    ) { }

  private readonly urlsWithoutAuth: string[] = [
    environment.apiUrl + '/auth'
  ];

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.authService.getAuthorizationToken();
    let request: HttpRequest<any> = req;
    const lastIndex = request.url.split('/').length - 1;
    const nameEndpoint = request.url.split('/')[lastIndex];
    if (this.urlsWithoutAuth.indexOf(request.url) === -1 && nameEndpoint !== 'password-resets') {
      request = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
      return next.handle(request).pipe(catchError(err => this.handleAuthError(err)));
    } else {
      return next.handle(request);
    }
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === this.NUMBER_401 || err.status === this.NUMBER_403) {
      sessionStorage.clear();
      localStorage.clear();
      this.dialog.closeAll();
      this.router.navigate(['/autenticacao/login']);
      return of(err.message);
    }
    return throwError(err);
  }
}
