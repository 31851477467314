<div class="modal-container">
  <div class="head">
    <div class="title">
      Inserir - Exceção de parâmetros NINA
    </div>
    <mat-icon class="icon" (click)="handleClick(false)">close</mat-icon>

  </div>
  <div class="line"></div>
  <div class="text">
    Olá usuário! Para inserir a exceção, preencha os dados abaixo:
  </div>

  <div class="inputs">
    <div class="item">
      <select-search-input #selectSubstation [selectInput]="selectInterface[0]" (selectionChange)="onValueEmitterSelectInput('substation',$event)"></select-search-input>
    </div>
    <div class="item">
      <number-suffix-input (valueEmitter)="onValueNumberInput('value',$event)"  [textInput]="numberSuffixInterface" ></number-suffix-input>
    </div>
  </div>
  <div class="line"></div>

  <div class="action">
    <button-induxtry *ngIf="data?.cancelButton" (action)="handleClick(false)"
        [buttonInduxtry]="{ label: data?.cancelButton?.text, customStyle: 'cancel', showLabel:true, disabled: false }">
    </button-induxtry >
    <button-induxtry *ngIf="data?.validationButton" (action)="handleClick(true)" 
        [buttonInduxtry]="{ label: data?.validationButton?.text, customStyle: 'primary', showLabel:true, disabled: disabledButtons() }">
    </button-induxtry>
  </div>
</div>
