import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { EventDispatcherService } from 'src/app/internal/services/eventDispatcher.service';
import { InternalService } from 'src/app/internal/services/internal.service';
import { ModalLoadingComponent } from '../../loads/modal-loading/modal-loading.component';
const DATE_FORMAT = 'DD/MM/AAAA';

@Component({
  selector: 'app-modal-add-manual-occurrence',
  templateUrl: './modal-add-manual-occurrence.component.html',
  styleUrls: ['./modal-add-manual-occurrence.component.scss']
})
export class ModalAddManualOccurrenceComponent implements OnInit {

  form = this.formBuilder.group({
    timeValue: new FormControl(null,Validators.required),
    datapickerValue: new FormControl(null,Validators.required),
    description: new FormControl(null,Validators.required),
  });

  timerInterface =  { disabled: false, value: this.form.value.timeValue, label: 'Hora', placeholder: 'HH:MM' }
  datapickerInterface =  { disabled: false, date: this.form.value.datapickerValue, label: 'Data', placeholder: DATE_FORMAT }
  checkBoxInterface = [
    { disabled: false, label: "Inserir histórico de mensagens no whatsapp", checked:false},
    { disabled: false, label: "Enviar dados da ocorrência por e-mail", checked:false}]
  textAreaInterface =  { value: this.form.value.description, label: 'Informações', rows: 17, placeholder: 'Insira aqui a consideração que deseja enviar' };


  constructor(    
    public dialogRef: MatDialogRef<ModalAddManualOccurrenceComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    private readonly internalService: InternalService,
    private readonly toastr: ToastrService,
    private readonly eventDispatcher: EventDispatcherService,
    public dialog: MatDialog,

    ) { }

  ngOnInit(): void {
  }

  onCheckChange(event,idx){
    this.checkBoxInterface[idx].checked = event;
  }

  handleClick(type): void {

    if(!type) this.dialogRef.close(type);
    else{

      const dialog = this.dialog.open(ModalLoadingComponent, {
        width: '400px',
        disableClose: true,
        data: {
          title: 'Carregando',
          text: 'Por favor espere alguns segundos',
        },
      });

      const date = new Date(this.form.value.datapickerValue)
      date.setHours(this.form.value.timeValue.substr(0,2),this.form.value.timeValue.substr(3));


      if(this.data.screen == 'Occurrence'){
        const sendObj = {
          date,
          text: this.form.value.description,
          sendGroupHistory: this.checkBoxInterface[0].checked,
          sendEmail: this.checkBoxInterface[1].checked
        }

        this.internalService.addManualOccorrence(this.data.id,sendObj).subscribe(resp=>{
          this.toastr.show('Ocorrência adicionada com sucesso', 'Sucesso', { timeOut: 500 , messageClass: 'success'});
          this.eventDispatcher.emitEditNotifyOccurrence(true)
          this.dialogRef.close(true);
          dialog.close();

        },error => {
          this.toastr.show('Algo de errado aconteceu, tente novamente.', 'Erro', { timeOut: 5000 , messageClass: 'error'});
          dialog.close();

        })
      }else{
        const sendObj = {
          date,
          information: this.form.value.description,
          sendGroupHistory: this.checkBoxInterface[0].checked,
        }
        this.internalService.addManualSupervision(this.data.id,sendObj).subscribe(resp=>{
          this.toastr.show('Ocorrência adicionada com sucesso', 'Sucesso', { timeOut: 500 , messageClass: 'success'});
          this.eventDispatcher.emitReloadSupervision(true);
          this.dialogRef.close(true);
          dialog.close();

        },error => {
          this.toastr.show('Algo de errado aconteceu, tente novamente.', 'Erro', { timeOut: 5000 , messageClass: 'error'});
          dialog.close();

        })
      } 
    }
  }

  onValueEmitterTextAreaInput(newValue,field){
    this.form.get(field).setValue(newValue)
  }

  onValueDate(event){
    this.form.get('datapickerValue').setValue(new Date(event))

  }

  onValueTime(event){
    //this.form.get('timeValue').setValue(event)
  }

}
