import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, ChangeDetectorRef, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-page-select',
  templateUrl: './page-select.component.html',
  styleUrls: ['./page-select.component.scss']
})
export class PageSelectComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() length: number;
  @Input() pageSize: number;
  @Input() pageSizeOptions: number[];
  @Input() selectedPage = 1;

  @Input() hasBoxShadow = true;
  @Output() itensOutput = new EventEmitter();
  @Output() emitPageSelected = new EventEmitter();

  select = new FormControl();
  pages;
  count = 0;

  constructor(private readonly cdr: ChangeDetectorRef) {
    this.cdr = cdr;
  }

  ngOnInit(): void { }

  ngOnChanges() {
    this.pagesNumber();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  pagesNumber() {
    this.selectedPage = this.selectedPage === undefined ? 1 : this.selectedPage;

    if (this.count === 0) {
      this.pageSizeOptions.push(this.length);
      this.count++;
    }
    this.pageSizeOptions[this.pageSizeOptions.length - 1] = this.length;
    if (this.pageSize === 0) {
      this.pages = 1;
    } else {
      this.pages = Math.ceil(this.length / this.pageSize);
    }
    if (this.length < this.pageSize) {
      this.select.setValue(this.length);
    } else {
      this.select.setValue(this.pageSize);
    }
  }

  changePag(op) {
    if (op === '+' && this.selectedPage < this.pages) {
      this.selectedPage++;
      this.emitPageSelected.emit(this.selectedPage);
    } else if (op === '-' && this.selectedPage > 1) {
      this.selectedPage--;
      this.emitPageSelected.emit(this.selectedPage);
    }
  }

  switchToThisPage(idx) {
    this.selectedPage = idx;
    this.emitPageSelected.emit(this.selectedPage);
  }

  changeManyItens(itens) {
    this.pageSize = itens;
    this.selectedPage = 1;
    this.pagesNumber();
    this.itensOutput.emit(itens);
  }

  styleObject(idx): Object {
    if (this.selectedPage === idx) {
      return {
              'background-color': '#E6F1F9',
              'color':'#0074C0',
              'font-weight': 'bold'
              };
    }
    return {};
  }

}
