import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DividerComponent } from '../divider/divider.component';

@Component({
  selector: 'cards[tag]',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent  implements OnInit {

  @ViewChild('divider') dividerComponent: DividerComponent;

  @Input() tag: string;
  @Input() title: string;
  @Input() divider = true;
  @Input() width: string | null;
  @Input() height: string | null;
  @Input() icon: string = null;
  ngOnInit(): void {
    if (this.tag === '') {
      this.tag = 'div';
    }
  }

}
