import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Clipboard} from '@angular/cdk/clipboard';
import { MatTooltip } from '@angular/material/tooltip';
import { CheckboxInput } from 'src/utils/models/input.interface';

@Component({
  selector: 'modal-group-selection',
  templateUrl: './modal-group-selection.component.html',
  styleUrls: ['./modal-group-selection.component.scss']
})
export class ModalGroupSelectionComponent {
  @ViewChild("myTooltip") myTooltip: MatTooltip
  checkBoxInterface: CheckboxInput [] = []
  listGroup = [];
  disabled = false;

  constructor(
    public dialogRef: MatDialogRef<ModalGroupSelectionComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private clipboard: Clipboard) { }
  

  handleClick(type: boolean): void {
    this.dialogRef.close(type);
  }
  
  ngOnInit() {
    this.listGroup = this.data.listGroup;
    this.createCheckboxList();
  }

  createCheckboxList(){
    this.checkBoxInterface = [];
    for(let i = 0; i < this.listGroup.length; i++ ){
      this.checkBoxInterface.push({disabled: false, checked: true, label: ''})
    }
  }

  onCheckChange(event,occurrence){
    if(event){
      this.listGroup.push(occurrence)
    }else{
      this.listGroup = this.listGroup.filter(el=>{return el.id != occurrence.id})
    }
    if(this.listGroup.length>1){
      this.disabled = false;
    }else{
      this.disabled = true;
    }
  }


}
