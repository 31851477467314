export interface NumberInput {
    disabled?: boolean;
    readonly?: boolean;
    minimo: number;
    maximo: number;
    step: number;
    value: number;
    placeholder: string;
    label: string;
}

export interface TextInput {
    disabled?: boolean;
    readonly?: boolean;
    value: string;
    label: string;
    placeholder: string;
    obs?: string;
    hint?: string;
    regexHint?: RegExp;
    required?:boolean
}

export interface NumberSuffixInput{
    disabled?: boolean;
    readonly?: boolean;
    value: string;
    label: string;
    placeholder: string;
    obs?: string;
    suffix?: string;
}

export interface PrefixInput {
    disabled?: boolean;
    readonly?: boolean;
    prefix: string;
    value: string;
    placeholder: string;
    label: string;
}

export interface SuffixInput {
    disabled?: boolean;
    readonly?: boolean;
    suffix: string;
    value: string;
    placeholder: string;
    label: string;
}

export interface TextAreaInput {
    disabled?: boolean;
    readonly?: boolean;
    rows: number;
    value: string;
    placeholder: string;
    label: string;
    hasEditableIcon?:boolean;
}

export interface SelectInput {
    disabled?: boolean;
    readonly?: boolean;
    placeholder: string;
    label: string;
    value: string;
    obs?:string,
    options: OptionsSelect[];
  }

  export interface MultiSelectInput {
    disabled?: boolean;
    readonly?: boolean;
    placeholder: string;
    label: string;
    options: OptionsSelect[];
    selectedItem: [];
    obs?:string
}

export interface infiniteSelect{
    disabled?: boolean;
    readonly?: boolean;
    placeholder: string;
    label: string;
    options: string[];
    selectedItem: [];
    obs?:string
    labelShow?:string
}

export interface OptionsSelect {
    value: number;
    viewValue: string;
}

export interface DatapickerInput {
  disabled?: boolean;
  readonly?: boolean;
  date: Date | null;
  placeholder: string;
  label: string;
  obs?:string;
  required?:boolean
}
export interface FileInput {
    disabled?: boolean;
    readonly?: boolean;
    types: string[];
    value: string;
    placeholder: string;
    label: string;
}

export interface CheckboxInput {
    disabled?: boolean;
    label: string;
    checked: boolean;
}

export interface SwitchInput {
    disabled?: boolean;
    label: string;
    checked: boolean;
}

export interface RadioButtonInput {
    disabledAll?: boolean;
    options: OptionsRadioButton[];
}

export interface GroupOccurrences {
    parentDataId: string,
    id: string;
    name: string;
    status: string;
    endDate: Date;
    startDate: Date;
    seqEvents: SeqEvent[]
}

export interface SeqEvent{
    date: Date;
    status:string;
    type: string;
    description: string;
    breakers?: string;
    trips?: string;
    warnings?: string;
}

interface OptionsRadioButton {
    value: number;
    viewValue: string;
    checked: boolean;
    disabled?: boolean;
}

export interface IntegrationValue {
    name: string;
    status: string;
    lastTry: Date;
    lastSuccessTry: Date;
}

type buttonStyles = 'primary' | 'secondary' | 'cancel' | 'tertiary' | null;
export interface ButtonInduxtry {
    label: string;
    disabled?: boolean;
    icon?: string | null;
    showLabel?: boolean;
    customStyle?: buttonStyles;
    width?:string;
    alert?: boolean
}
