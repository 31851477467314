<div class="modal-container">
  <div class="head">
    <div class="title">
      <mat-icon *ngIf="data?.type" [svgIcon]="data?.type"></mat-icon>
      {{ data?.title }}
    </div>
    <mat-icon class="icon" (click)="handleClick(false)">close</mat-icon>
  </div>
  <div class="line"></div>

  <div class="content-text">
          <div class="subtitle">DETALHAMENTO DO ERRO</div>
          <div class="system">Sistema {{data.system}} indisponível</div>
          <div class="text">Última tentativa de conexão com sucesso {{data.lastSuccessTry}}</div>
          <div class="text">Estamos buscando nova conexão a cada {{data.intervalTry}} minutos.</div>
  </div>

  <div class="text" *ngIf="data?.text">
    {{ data?.text }}
    <br/><br/>
    {{ data?.pergunta }}

  </div>
 <!--  <div class="action">
    <button-induxtry *ngIf="data?.cancelButton" (action)="handleClick(false)" 
        [buttonInduxtry]="{ label: data?.cancelButton?.text, customStyle: 'cancel' }">
    </button-induxtry >
    <button-induxtry *ngIf="data?.validationButton" (action)="handleClick(false)" 
        [buttonInduxtry]="{ label: data?.validationButton?.text }">
    </button-induxtry>
  </div> -->
  <div class="line"></div>

  <div class="row-date-button">
    <span class="date">{{data.dateString}}</span>
    <span 
      [matTooltipDisabled]="true"
      #myTooltip="matTooltip" 
      matTooltip="Texto copiado!"
      class="simple-button" (click)="copyToClipboard()">     
      <mat-icon [svgIcon]="'Duplicate'"></mat-icon>
      COPIAR TEXTO</span>
  </div>
</div>
