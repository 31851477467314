import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Clipboard} from '@angular/cdk/clipboard';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'unavailability-modal',
  templateUrl: './unavailability-modal.component.html',
  styleUrls: ['./unavailability-modal.component.scss']
})
export class UnavailabilityModalComponent {
  @ViewChild("myTooltip") myTooltip: MatTooltip

  constructor(
    public dialogRef: MatDialogRef<UnavailabilityModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private clipboard: Clipboard) { }

  handleClick(type: boolean): void {
    this.dialogRef.close(type);
  }

  copyToClipboard(){

    this.clipboard.copy(`Sistema ${this.data.system} indisponível
    Última tentativa de conexão com sucesso ${this.data.lastSuccessTry}
      Estamos buscando nova conexão a cada ${this.data.intervalTry} minutos.`
    );
    this.myTooltip.disabled = false;
    this.myTooltip.show()
    setTimeout(() => {
      this.myTooltip.disabled = true;
    }, 1000);
  }

}
