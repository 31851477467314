import { RequestCenario, RequestEquipamento, RequestParamsLogStatus } from './../../../utils/models/http.interface';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RequestParams, RequestAttribute, RequestParamsLog } from '../../../utils/models/http.interface';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private readonly apiUrl: string = environment.apiUrl;

  constructor(private readonly http: HttpClient) { }

  genericGet<T>(endpoint: string, id?: string): Observable<T> {
    const url = id ? `${this.apiUrl}/${endpoint}/${id}` : `${this.apiUrl}/${endpoint}`;
    return this.http.get<T>(url)
      .pipe(map((response: T) => response));
  }

  genericGetWithId<T>(endpoint: string, id: string, attribute?: RequestAttribute[]): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}/${id}`;
    let params = new HttpParams();
    if (!!attribute) {
      attribute.forEach((att) => params = params.append(att.param, att.value));
    }
    return this.http.get<T>(url, { params })
      .pipe(map((response: T) => {
        return response;
      }));
  }

  genericGetParams<T>(endpoint: string, attribute: RequestAttribute[]): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`;
    let params = new HttpParams();
    if (!!attribute) {
      attribute.forEach((att) => params = params.append(att.param, att.value));
    }
    return this.http.get<T>(url, { params })
      .pipe(map((response: T) => {
        return response;
      }));
  }

  genericGetList<T>( endpoint: string, requestParams: RequestParams, attribute?: RequestAttribute[], type?: string ): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`
    let params
    if (type === 'page') {
      params = new HttpParams()
        .set('skip', requestParams.skip.toString())
        .set('sort', requestParams.sort)
        .set('page', requestParams.page.toString())
        .set('limit', requestParams.limit.toString())
    } else if (type === 'skip') {
      params = new HttpParams().set('skip', requestParams.skip.toString())
    } else {
      params = new HttpParams()
        .set('skip', requestParams.skip.toString())
        .set('sort', requestParams.sort)
        .set('limit', requestParams.limit.toString())
    }

    if (!!attribute) {
      attribute.forEach((att) => (params = params.append(att.param, att.value)))
    }
    return this.http.get<T>(url, { params }).pipe(
      map((response: T) => {
        return response
      })
    )
  }


  genericGetListTable<T>(endpoint: string, requestParams: RequestParams, attribute?: RequestAttribute[], filterAttribute?: RequestAttribute[]): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`;
    let params = new HttpParams()
      .set('skip', requestParams.skip.toString())
      .set('sort', requestParams.sort)
      .set('limit', requestParams.limit.toString());
    if (!!attribute) {
      attribute.forEach((att) => params = params.append(att.param, att.value));
    }
    if (!!filterAttribute) {
      filterAttribute.forEach((att) => params = params.append(att.param, att.value));
    }
    return this.http.get<T>(url, { params })
      .pipe(map((response: T) => {
        return response;
      }));
  }


  genericPost<T>(endpoint: string, data: any, headers: any): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders(headers)
    };
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.post<T>(url, data, httpOptions)
      .pipe(map((response: T) => response));
  }

  genericPostDownload<T>(endpoint: string, body: any, attribute?: RequestAttribute[]): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`;
    let params = new HttpParams();

    if (!!attribute) {
      attribute.forEach((att) => params = params.append(att.param, att.value));
    }
    return this.http.post(url, body, {responseType:'arraybuffer'})
      .pipe((response: any) => {
        return response;
      });
  }

  genericPostNotData<T>(endpoint: string, data: any): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.post<T>(url, data)
      .pipe(map((response: T) => response));
  }

  genericPut<T>(endpoint: string, data: any, id?: string): Observable<T> {
    const url = id ? `${this.apiUrl}/${endpoint}/${id}` : `${this.apiUrl}/${endpoint}`

    return this.http.put<T>(url, data)
      .pipe(map((response: T) => response));
  }

  genericDelete<T>(endpoint: string, id: string): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}/${id}`;
    return this.http.delete<T>(url)
      .pipe(map((response: T) => response));
  }

  genericDeleteBody<T>(endpoint: string, id: string, data: any): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body:data
    };

    const url = `${this.apiUrl}/${endpoint}/${id}`;
    return this.http.delete<T>(url, options)
      .pipe(map((response: T) => {
        return response;
      }));
  }

  genericDeleteAll<T>(endpoint: string, id: string, attribute?: RequestAttribute[]): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}/${id}`;
    let params = new HttpParams();
    if (!!attribute) {
      attribute.forEach((att) => params = params.append(att.param, att.value));
    }
    return this.http.delete<T>(url, { params })
      .pipe(map((response: T) => {
        return response;
      }));
  }

  genericPutBody<T>(endpoint: string, body: any, attribute?: RequestAttribute[]): Observable<T> {
    const url = `${this.apiUrl}/${endpoint}`;
    let params = new HttpParams()
    if (!!attribute) {
      attribute.forEach((att) => params = params.append(att.param, att.value));
    }
    return this.http.put<T>(url, body,{params})
      .pipe(map((response: T) => {
        return response;
      }));
  }
}
