import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'select-search-input',
  templateUrl: './select-search-input.component.html',
  styleUrls: ['./select-search-input.component.scss']
})
export class SelectSearchInputComponent implements OnInit {


  form = this.formBuilder.group({
    filter: new FormControl(),
  })

  constructor(private readonly formBuilder: FormBuilder) { }

  @Input() selectInput;
  @Output() selectionChange = new EventEmitter();

  ngOnInit(): void {
  }

  onValueEmitterSelectInput(event){
    this.selectionChange.emit(event)
  }
  clearFilter(){
    this.selectionChange.emit([])
  }

}
