import { EventEmitter, Injectable } from '@angular/core';
import { HttpService } from 'src/app/service/http/http.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EventDispatcherService {

  readonly editNotifyOccurrence = new EventEmitter();
  readonly statusSmartalarms = new EventEmitter();
  readonly hasNewOccurrences = new EventEmitter();
  readonly updateParamsEventGroup = new EventEmitter();
  readonly updateListParamsEventGroup = new EventEmitter();
  readonly updateListSupervision = new EventEmitter();
  readonly reloadSupervision = new EventEmitter();
  readonly notifyNewOccurrences = new EventEmitter();
  readonly notifyNewSupervision = new EventEmitter();
  readonly groupOccurrences = new EventEmitter();
  readonly enableGroupButton = new EventEmitter();
  readonly resetGroup = new EventEmitter();
  
  emitEditNotifyOccurrence(event) {
    this.editNotifyOccurrence.emit(event);
  }

  emitNotifyNewOccurrences(event) {
    this.notifyNewOccurrences.emit(event);
  }  
  
  emitNotifyNewSupervision(event) {
    this.notifyNewSupervision.emit(event);
  }

  emitReloadSupervision(event){
    this.reloadSupervision.emit(event);
  }

  emitStatusSmartalarms(event) {
    this.statusSmartalarms.emit(event);
  }

  emitHasNewOccurrences(event) {
    this.hasNewOccurrences.emit(event);
  }

  emitUpdateParamsEventGroup(event) {
    this.updateParamsEventGroup.emit(event);
  }

  emitUpdateListEventGroup(event) {
    this.updateListParamsEventGroup.emit(event);
  }

  emitUpdateListSupervision(event) {
    this.updateListSupervision.emit(event);
  }

  emitGroupOccurrences(event){
    this.groupOccurrences.emit(event);
  }

  emitEnableGroupButton(event){
    this.enableGroupButton.emit(event);
  }

  emitResetGroup(event) {
    this.resetGroup.emit(event);
  }
}