import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';

import { SelectInfiniteScrollSearchComponent } from './components/scrolls/select-infinite-scroll-search/select-infinite-scroll-search.component';
import { ModalLoadingComponent } from './components/loads/modal-loading/modal-loading.component';
import { ModalLoadingSecComponent } from './components/loads/modal-loading-sec/modal-loading-sec.component';
import { MytoastrComponent } from './components/dialogs/mytoastr/mytoastr.component';
import { PageSelectComponent } from './components/common/page-select/page-select.component';

import { SwalComponent } from './components/dialogs/swal/swal.component';

import { DynamicTableComponent } from './components/tables/dynamic-table/dynamic-table.component';
import { FilterTableComponent } from './components/tables/filter-table/filter-table.component';
import { PopoverComponent } from './components/popover/popover.component';

import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import { NgxMaskModule } from 'ngx-mask';
import { ModalAddExceptionComponent } from './components/dialogs/modal-add-exception/modal-add-exception.component'
import { DoubleScrollComponent } from './components/scrolls/app-double-scroll/app-double-scroll.component';
import { TimePickerComponent } from './components/inputs/time-picker/time-picker.component';
import { CheckboxComponent } from './components/inputs/checkbox/checkbox.component';
import { SwitchComponent } from './components/inputs/switch/switch.component';
import { RadioButtonComponent } from './components/inputs/radio-button/radio-button.component';
import { ButtonComponent } from './components/inputs/button/button.component';
import { DividerComponent } from './components/common/divider/divider.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CardsComponent } from './components/common/cards/cards.component';
import { EmptyStateComponent } from './components/common/empty-state/empty-state.component';
import { TabsInduxtryComponent } from './components/common/tabs-induxtry/tabs-induxtry.component';
import { TabInduxtryComponent } from './components/common/tabs-induxtry/tab-induxtry/tab-induxtry.component';
import { LoadingComponent } from './components/loads/loading/loading.component';
import { AvatarComponent } from './components/miscelanea/avatar/avatar.component';
import { TagsComponent } from './components/miscelanea/tags/tags.component';
import { ChipsComponent } from './components/miscelanea/chips/chips.component';
import { LinksComponent } from './components/common/links/links.component';
import { ListaComponent } from './components/common/lista/lista.component';
import { TooltipComponent } from './components/miscelanea/tooltip/tooltip.component';
import { ChartComponent } from './components/graphics/chart/chart.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { TextInputComponent } from './components/inputs/text-input/text-input.component';
import { SelectInputComponent } from './components/inputs/select-input/select-input.component';
import { CalendarPickerComponent } from './components/inputs/calendar-picker/calendar-picker.component';
import { DatapickerInputComponent } from './components/inputs/datapicker-input/datapicker-input.component'; 
import { ButtonIconComponent } from './components/inputs/button-icon/button-icon.component';
import { ModalValidateEventComponent } from './components/dialogs/modal-validate-event/modal-validate-event.component';
import { TextareaInputComponent } from './components/inputs/textarea-input/textarea-input.component';
import { TimeInputComponent } from './components/inputs/time-input/time-input.component';
import { MultiselectInputComponent } from './components/inputs/multiselect-input/multiselect-input.component';
import { SelectSearchInputComponent } from './components/inputs/select-search-input/select-search-input.component';
import { ModalAddManualOccurrenceComponent } from './components/dialogs/modal-add-manual-occurrence/modal-add-manual-occurrence.component';
import { ModalFinishManualOccurrenceComponent } from './components/dialogs/modal-finish-manual-occurrence/modal-finish-manual-occurrence.component';
import { ModalLogsComponent } from './components/dialogs/modal-logs/modal-logs.component';
import { UnavailabilityAlertComponent } from './components/feedback/unavailability-alert/unavailability-alert.component';
import { UnavailabilityModalComponent } from './components/dialogs/unavailability-modal/unavailability-modal.component';
import { ModalCreateManualOccurrenceComponent } from './components/dialogs/modal-create-manual-occurrence/modal-create-manual-occurrence.component';
import { NumberSuffixInputComponent } from './components/inputs/number-suffix-input/number-suffix-input.component';
import { ModalValidateSupervisionComponent } from './components/dialogs/modal-validate-supervision/modal-validate-supervision.component';
import { ModalFinishManualSupervisionComponent } from './components/dialogs/modal-finish-manual-supervision/modal-finish-manual-supervision.component';
import { NumberInputComponent } from './components/inputs/number-input/number-input.component';
import { ModalGroupSelectionComponent } from './components/dialogs/modal-group-selection/modal-group-selection.component';
@NgModule({
  declarations: [
    SelectInfiniteScrollSearchComponent,
    SwalComponent,
    MytoastrComponent,
    ModalLoadingComponent,
    ModalLoadingSecComponent,
    PageSelectComponent,
    DynamicTableComponent,
    FilterTableComponent,
    PopoverComponent,
    ModalAddExceptionComponent,
    DoubleScrollComponent,
    TimePickerComponent,
    CheckboxComponent,
    SwitchComponent,
    RadioButtonComponent,
    ButtonComponent,
    DividerComponent,
    CardsComponent,
    EmptyStateComponent,
    TabsInduxtryComponent,
    TabInduxtryComponent,
    LoadingComponent,
    AvatarComponent,
    TagsComponent,
    ChipsComponent,
    LinksComponent,
    ListaComponent,
    TooltipComponent,
    ChartComponent,

    NumberSuffixInputComponent,
    NumberInputComponent,
    TextInputComponent,
    SelectInputComponent,
    CalendarPickerComponent,
    DatapickerInputComponent,
    ButtonIconComponent,
    TextareaInputComponent,
    TimeInputComponent,
    MultiselectInputComponent,
    SelectSearchInputComponent,
    
    ModalValidateEventComponent,
    ModalAddManualOccurrenceComponent,
    ModalFinishManualOccurrenceComponent,
    ModalLogsComponent,
    ModalCreateManualOccurrenceComponent,
    ModalFinishManualSupervisionComponent,
    UnavailabilityModalComponent,
    ModalValidateSupervisionComponent,
    UnavailabilityAlertComponent,
    ModalGroupSelectionComponent
     
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // Material
    LayoutModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatButtonModule,
    MatRadioModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatMenuModule,
    MatRippleModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSelectInfiniteScrollModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    NgxMatDatetimePickerModule,
    NgxMaterialTimepickerModule.setLocale('pt-BR'),
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatSelectSearchModule,
    ToastrModule.forRoot({
      toastComponent: MytoastrComponent, // added custom toast!
    }),
    NgxMaskModule.forRoot(),
    NgbModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // Material
    LayoutModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatButtonModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDatepickerModule,
    MatTabsModule,
    MatRippleModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    SelectInfiniteScrollSearchComponent,
    MatSelectInfiniteScrollModule,
    SwalComponent,
    ModalLoadingComponent,
    ModalLoadingSecComponent,
    PageSelectComponent,
    DynamicTableComponent,
    FilterTableComponent,
    PopoverComponent,
    NgxMatDatetimePickerModule,
    NgxMaterialTimepickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMaskModule,
    DoubleScrollComponent,
    TimePickerComponent,
    CheckboxComponent,
    SwitchComponent,
    RadioButtonComponent,
    ButtonComponent,
    DividerComponent,
    MatTooltipModule,
    CardsComponent,
    EmptyStateComponent,
    TabsInduxtryComponent,
    TabInduxtryComponent,
    LoadingComponent,
    AvatarComponent,
    TagsComponent,
    ChipsComponent,
    LinksComponent,
    ListaComponent,
    TooltipComponent,
    ChartComponent,
    NgxMatSelectSearchModule,
    MatProgressSpinnerModule,

    TextInputComponent,
    NumberSuffixInputComponent,
    NumberInputComponent,
    SelectInputComponent,
    CalendarPickerComponent,
    DatapickerInputComponent,
    ButtonIconComponent,
    TimeInputComponent,
    TextareaInputComponent,
    MultiselectInputComponent,
    SelectSearchInputComponent,

    //Modais
    ModalValidateEventComponent,
    ModalAddManualOccurrenceComponent,
    ModalFinishManualOccurrenceComponent,
    ModalFinishManualSupervisionComponent,
    ModalCreateManualOccurrenceComponent,
    ModalLogsComponent,
    UnavailabilityAlertComponent,
    ModalGroupSelectionComponent

  ],
  providers: [
    DatePipe,
    DecimalPipe
  ],
  entryComponents: [
    SwalComponent,
    MytoastrComponent,
    ModalLoadingComponent,
    ModalLoadingSecComponent,
    ModalAddExceptionComponent,
    ModalValidateEventComponent,
    ModalAddManualOccurrenceComponent,
    ModalCreateManualOccurrenceComponent,
    ModalValidateSupervisionComponent,
    ModalFinishManualSupervisionComponent,
    UnavailabilityAlertComponent,
    ModalGroupSelectionComponent
  ]
})
export class SharedModule {}
