<mat-form-field class="mat-form-field" [floatLabel]="'always'" appearance="outline" hideRequiredMarker [style.margin-bottom]="hasHint ? '16px' : '0px'">
    <mat-label class="mat-label-date">
        <span class="mat-label-date">{{ textInput.label }} <mat-icon [style.margin-left]="'2px'" *ngIf="textInput.disabled">lock</mat-icon></span>
        <span class="label-obs">{{textInput.obs}}
        </span>
        
    </mat-label>
    <input
        matInput
        [placeholder]="textInput.placeholder"
        type="text"
        [(ngModel)]="textInput.value"
        [disabled]="textInput.disabled"
        [readonly]="textInput.readonly"
        [required]="textInput.required"
        (input)="setValue()"
    >
    <mat-icon class="error-teste" style="display: none;
    width: 16px; height: 16px;" *ngIf="textInput.value == ''" matSuffix svgIcon="error"></mat-icon>
    <mat-icon *ngIf="hasHint" matSuffix svgIcon="notification"></mat-icon>
    <mat-hint *ngIf="hasHint" align="end">{{textInput.hint}}</mat-hint>
    <mat-error align="end">Preenchimento obrigatório</mat-error>
</mat-form-field>
