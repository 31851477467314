import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UnavailabilityModalComponent } from '../../dialogs/unavailability-modal/unavailability-modal.component';
import { InternalService } from 'src/app/internal/services/internal.service';
import { EventDispatcherService } from 'src/app/internal/services/eventDispatcher.service';

@Component({
  selector: 'app-unavailability-alert',
  templateUrl: './unavailability-alert.component.html',
  styleUrls: ['./unavailability-alert.component.scss']
})
export class UnavailabilityAlertComponent implements OnInit {

  unavaibilitySystem

  constructor(public dialog: MatDialog,
    private readonly eventDispatcher: EventDispatcherService,
    private readonly internalService: InternalService) { }

  ngOnInit(): void {
    this.getValues()
    setInterval(()=>{
     this.getValues()
    },300000)
  }

  getValues(){
    this.internalService.listIntegrations().subscribe((res:any)=>{
      this.unavaibilitySystem = res.filter(el=>{
        return el.name == "Smartalarms" ? 
                !el.avaiability.every(el=>el.avaiable) :
                !el.avaiable
      })[0]
      if(this.unavaibilitySystem && this.unavaibilitySystem.name == "Smartalarms"){
        this.eventDispatcher.emitStatusSmartalarms(true)
      } else{
        this.eventDispatcher.emitStatusSmartalarms(false)
      }
      if(this.unavaibilitySystem){
        const htmlElement = document.getElementsByClassName("mat-drawer-content") as HTMLCollectionOf<HTMLElement>;
        this.unavaibilitySystem.name = this.unavaibilitySystem.name.toUpperCase()
        htmlElement[0].style.height = "calc(100% - 88px)"
      } else{
        const htmlElement = document.getElementsByClassName("mat-drawer-content") as HTMLCollectionOf<HTMLElement>;
        htmlElement[0].style.height = "100%"
      }
    })
  }

  action(){
   const eventDate = new Date()
    this.dialog.open(UnavailabilityModalComponent, {
      disableClose: true,
      height: '272px',
      width: '553px',
      panelClass: 'custom-modalbox',
      //maxHeight: '941px',
      data: {
        title: `Indisponibilidades encontradas: Indisponibilidade na comunicação com o ${this.unavaibilitySystem.name}`,
        error: 'Código 1001',
        system: this.unavaibilitySystem.name,
        lastSuccessTry: this.unavaibilitySystem.name == "SMARTALARMS" ? 
          (`${new Date(this.unavaibilitySystem.avaiability[0].lastSuccess).toLocaleDateString()} - ${new Date(this.unavaibilitySystem.avaiability[0].lastSuccess).toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'})}`) : 
          (`${new Date(this.unavaibilitySystem.lastSuccess).toLocaleDateString()} - ${new Date(this.unavaibilitySystem.lastSuccess).toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'})}`),
            //`${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'})}`,
        intervalTry: 5,
        type: 'error',
        dateString: eventDate.getDate() == new Date().getDate() ? `Hoje ${eventDate.toLocaleTimeString("pt-BR",{hour: '2-digit', minute:'2-digit'})}` : `${eventDate.toLocaleDateString()} às  ${eventDate.toLocaleTimeString("pt-BR",{hour: '2-digit', minute:'2-digit'})}`,
        cancelButton: {text: 'CANCELAR'},
        validationButton: {text: 'FINALIZAR'},
      }
    })
  }

}
