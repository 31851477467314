import { sequence, trigger, animate, style, group, query, transition,
         animateChild, state, animation, useAnimation, stagger } from '@angular/animations';

const ANIMATION_300_EASE_OUT = '300ms ease-out';
const ANIMATION_300_EASE_IN = '300ms ease-in';
const TRANSLATEX_100 = 'translateX(100%)';
const TRANSLATEX_0 = 'translateX(0)';
const TRANSLATEX_NEG_100 = 'translateX(-100%)';
const TRANSLATEY_100 = 'translateY(100%)';
const TRANSLATEY_0 = 'translateY(0)';
const TRANSLATEY_NEG_100 = 'translateY(-100%)';
const QUERY_ENTER_LEAVE = 'content > :enter, content > :leave';
const QUERY_ENTER = 'content > :enter';
const QUERY_LEAVE = 'content > :leave';
const ANIMATION_600_CUBIC = '600ms cubic-bezier(0.0, 0.0, 0.2, 1)';

const customAnimation = animation([
  style({
    opacity  : '{{opacity}}',
    transform: 'scale({{scale}}) translate3d({{x}}, {{y}}, {{z}})'
  }),
  animate('{{duration}} {{delay}} cubic-bezier(0.0, 0.0, 0.2, 1)', style('*'))
], {
  params: {
    duration: '200ms',
    delay   : '0ms',
    opacity : '0',
    scale   : '1',
    x       : '0',
    y       : '0',
    z       : '0'
  }
});

export const animations = [

  trigger('animate', [transition('void => *', [useAnimation(customAnimation)])]),

  trigger('animateStagger', [
    state('50', style('*')),
    state('100', style('*')),
    state('200', style('*')),

    transition('void => 50',
      query('@*',
        [
          stagger('50ms', [
            animateChild()
          ])
        ], {optional: true})),
    transition('void => 100',
      query('@*',
        [
          stagger('100ms', [
            animateChild()
          ])
        ], {optional: true})),
    transition('void => 200',
      query('@*',
        [
          stagger('200ms', [
            animateChild()
          ])
        ], {optional: true}))
  ]),

  trigger('fadeInOut', [
    state('0, void', style({
      opacity: 0
    })),
    state('1, *', style({
      opacity: 1
    })),
    transition('1 => 0', animate(ANIMATION_300_EASE_OUT)),
    transition('0 => 1', animate(ANIMATION_300_EASE_IN)),
    transition('void <=> *', animate(ANIMATION_300_EASE_IN))
  ]),

  trigger('fadeInOutMin', [
    state('0, void', style({
      opacity: 0
    })),
    state('1, *', style({
      opacity: 1
    })),
    transition('1 => 0', animate('200ms ease-out')),
    transition('0 => 1', animate('200ms ease-in')),
    transition('void <=> *', animate('200ms ease-in'))
  ]),

  trigger('slideInOut', [
    state('0', style({
      height: '0px'
    })),
    state('1', style({
      height: '*'
    })),
    transition('1 => 0', animate(ANIMATION_300_EASE_OUT)),
    transition('0 => 1', animate(ANIMATION_300_EASE_IN))
  ]),

  trigger('slideIn', [
    transition('void => left', [
        style({
          transform: TRANSLATEX_100
        }),
        animate(ANIMATION_300_EASE_IN,
          style({
            transform: TRANSLATEX_0
          })
        )
      ]
    ),
    transition('left => void', [
        style({
          transform: TRANSLATEX_0
        }),
        animate(ANIMATION_300_EASE_IN,
          style({
            transform: TRANSLATEX_NEG_100
          })
        )
      ]
    ),
    transition('void => right', [
        style({
          transform: TRANSLATEX_NEG_100
        }),
        animate(ANIMATION_300_EASE_IN,
          style({
            transform: TRANSLATEX_0
          })
        )
      ]
    ),
    transition('right => void', [
        style({
          transform: TRANSLATEX_0
        }),
        animate(ANIMATION_300_EASE_IN,
          style({
            transform: TRANSLATEX_100
          })
        )
      ]
    )
  ]),

  trigger('slideInLeft', [
    state('void', style({
      transform: TRANSLATEX_NEG_100,
    })),
    state('*', style({
      transform: TRANSLATEX_0,
    })),
    transition('void => *', animate('300ms')),
    transition('* => void', animate('300ms'))
  ]),

  trigger('slideInRight', [
    state('void', style({
      transform: TRANSLATEX_100,
    })),
    state('*', style({
      transform: TRANSLATEX_0,
    })),
    transition('void => *', animate('300ms')),
    transition('* => void', animate('300ms'))
  ]),

  trigger('slideInTop', [
    state('void', style({
      transform: TRANSLATEY_NEG_100,
    })),
    state('*', style({
      transform: TRANSLATEY_0,
    })),
    transition('void => *', animate('300ms')),
    transition('* => void', animate('300ms'))
  ]),

  trigger('slideInBottom', [
    state('void',
      style({
        transform: TRANSLATEY_100,
      })),
    state('*', style({
      transform: TRANSLATEY_0,
    })),
    transition('void => *', animate('300ms')),
    transition('* => void', animate('300ms'))
  ]),

  trigger('expandCollapse', [
    state('void', style({
      height: '0px'
    })),
    state('*', style({
      height: '*'
    })),
    transition('void => *', animate(ANIMATION_300_EASE_OUT)),
    transition('* => void', animate(ANIMATION_300_EASE_IN))
  ]),

  // -----------------------------------------------------------------------------------------------------
  // @ Router animations
  // -----------------------------------------------------------------------------------------------------

  trigger('routerTransitionLeft', [

    transition('* => *', [
      query(QUERY_ENTER_LEAVE, [
        style({
          position: 'absolute',
          top     : 0,
          bottom  : 0,
          left    : 0,
          right   : 0
        })
      ], {optional: true}),
      query(QUERY_ENTER, [
        style({
          transform: TRANSLATEX_100,
          opacity  : 0
        })
      ], {optional: true}),
      sequence([
        group([
          query(QUERY_LEAVE, [
            style({
              transform: TRANSLATEX_0,
              opacity  : 1
            }),
            animate(ANIMATION_600_CUBIC,
              style({
                transform: TRANSLATEX_NEG_100,
                opacity  : 0
              }))
          ], {optional: true}),
          query(QUERY_ENTER, [
            style({transform: TRANSLATEX_100}),
            animate(ANIMATION_600_CUBIC,
              style({
                transform: TRANSLATEX_0,
                opacity  : 1
              }))
          ], {optional: true})
        ]),
        query(QUERY_LEAVE, animateChild(), {optional: true}),
        query(QUERY_ENTER, animateChild(), {optional: true})
      ])
    ])
  ]),

  trigger('routerTransitionRight', [

    transition('* => *', [
      query(QUERY_ENTER_LEAVE, [
        style({
          position: 'absolute',
          top     : 0,
          bottom  : 0,
          left    : 0,
          right   : 0
        })
      ], {optional: true}),
      query(QUERY_ENTER, [
        style({
          transform: TRANSLATEX_NEG_100,
          opacity  : 0
        })
      ], {optional: true}),
      sequence([
        group([
          query(QUERY_LEAVE, [
            style({
              transform: TRANSLATEX_0,
              opacity  : 1
            }),
            animate(ANIMATION_600_CUBIC,
              style({
                transform: TRANSLATEX_100,
                opacity  : 0
              }))
          ], {optional: true}),
          query(QUERY_ENTER, [
            style({transform: TRANSLATEX_NEG_100}),
            animate(ANIMATION_600_CUBIC,
              style({
                transform: TRANSLATEX_0,
                opacity  : 1
              }))
          ], {optional: true})
        ]),
        query(QUERY_LEAVE, animateChild(), {optional: true}),
        query(QUERY_ENTER, animateChild(), {optional: true})
      ])
    ])
  ]),

  trigger('routerTransitionUp', [

    transition('* => *', [
      query(QUERY_ENTER_LEAVE, [
        style({
          position: 'absolute',
          top     : 0,
          bottom  : 0,
          left    : 0,
          right   : 0
        })
      ], {optional: true}),
      query(QUERY_ENTER, [
        style({
          transform: TRANSLATEY_100,
          opacity  : 0
        })
      ], {optional: true}),
      group([
        query(QUERY_LEAVE, [
          style({
            transform: TRANSLATEY_0,
            opacity  : 1
          }),
          animate(ANIMATION_600_CUBIC,
            style({
              transform: TRANSLATEY_NEG_100,
              opacity  : 0
            }))
        ], {optional: true}),
        query(QUERY_ENTER, [
          style({transform: TRANSLATEY_100}),
          animate(ANIMATION_600_CUBIC,
            style({
              transform: TRANSLATEY_0,
              opacity  : 1
            }))
        ], {optional: true})
      ]),
      query(QUERY_LEAVE, animateChild(), {optional: true}),
      query(QUERY_ENTER, animateChild(), {optional: true})
    ])
  ]),

  trigger('routerTransitionDown', [

    transition('* => *', [
      query(QUERY_ENTER_LEAVE, [
        style({
          position: 'absolute',
          top     : 0,
          bottom  : 0,
          left    : 0,
          right   : 0
        })
      ], {optional: true}),
      query(QUERY_ENTER, [
        style({
          transform: TRANSLATEY_NEG_100,
          opacity  : 0
        })
      ], {optional: true}),
      sequence([
        group([
          query(QUERY_LEAVE, [
            style({
              transform: TRANSLATEY_0,
              opacity  : 1
            }),
            animate(ANIMATION_600_CUBIC,
              style({
                transform: TRANSLATEY_100,
                opacity  : 0
              }))
          ], {optional: true}),
          query(QUERY_ENTER, [
            style({transform: TRANSLATEY_NEG_100}),
            animate(ANIMATION_600_CUBIC,
              style({
                transform: TRANSLATEY_0,
                opacity  : 1
              }))
          ], {optional: true})
        ]),
        query(QUERY_LEAVE, animateChild(), {optional: true}),
        query(QUERY_ENTER, animateChild(), {optional: true})
      ])
    ])
  ]),

  trigger('routerTransitionFade', [

    transition('* => *', group([

      query('content > :enter, content > :leave ', [
        style({
          position: 'absolute',
          top     : 0,
          bottom  : 0,
          left    : 0,
          right   : 0
        })
      ], {optional: true}),

      query(QUERY_ENTER, [
        style({
          opacity: 0
        })
      ], {optional: true}),
      query(QUERY_LEAVE, [
        style({
          opacity: 1
        }),
        animate('300ms cubic-bezier(0.0, 0.0, 0.2, 1)',
          style({
            opacity: 0
          }))
      ], {optional: true}),
      query(QUERY_ENTER, [
        style({
          opacity: 0
        }),
        animate('300ms cubic-bezier(0.0, 0.0, 0.2, 1)',
          style({
            opacity: 1
          }))
      ], {optional: true}),
      query(QUERY_ENTER, animateChild(), {optional: true}),
      query(QUERY_LEAVE, animateChild(), {optional: true})
    ]))
  ])
];
