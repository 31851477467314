import { InjectionToken, NgModule, APP_INITIALIZER } from '@angular/core';
import {
  IPublicClientApplication, PublicClientApplication,
  LogLevel
} from '@azure/msal-browser';
import {
  MsalGuard, MsalInterceptor, MsalBroadcastService,
  MsalInterceptorConfiguration, MsalModule, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfigService } from './config.service';
import { environment } from 'src/environments/environment';


const AUTH_CONFIG_URL_TOKEN = new InjectionToken<string>('AUTH_CONFIG_URL');

export function initializerFactory(env: ConfigService, configUrl: string): any {
  const promise = env.init(configUrl).then((value) => { });
  return () => promise;
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal



export function MSALInstanceFactory(config: ConfigService): IPublicClientApplication {

  const auxAuth = {
    'clientId': environment.client,//config.getSettings('CLIENT'),
    'authority': environment.authority,//config.getSettings('AUTHORITY'),
    'redirectUri': environment.redirectUri//config.getSettings('REDIRECT_URI')
  }
  const auxCache = {
    'cacheLocation': 'localStorage',
    'storeAuthStateInCookie': true
  }
  return new PublicClientApplication({
    auth: auxAuth,
    cache: auxCache,
    system: {
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(config: ConfigService): MsalInterceptorConfiguration {
  const interceptor:any = {
    'interactionType': 'redirect',
    'protectedResourceMap': [
      ['https://graph.microsoft.com/v1.0/me', ['user.read']]
    ]
  }
  const protectedResourceMap = new Map<string, Array<string>>(interceptor.protectedResourceMap)
  return {
    interactionType: interceptor.interactionType,
    protectedResourceMap
  };
}


@NgModule({
  providers: [],
  imports: [MsalModule]
})
export class MsalConfigDynamicModule {

  static forRoot(configFile: string) {
    return {
      ngModule: MsalConfigDynamicModule,
      providers: [
        ConfigService,
        { provide: AUTH_CONFIG_URL_TOKEN, useValue: configFile },
        {
          provide: APP_INITIALIZER,
          useFactory: initializerFactory,
          deps: [ConfigService, AUTH_CONFIG_URL_TOKEN],
          multi: true
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [ConfigService]
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [ConfigService]
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        }
      ]
    };
  }
}
