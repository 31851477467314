import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { EventDispatcherService } from 'src/app/internal/services/eventDispatcher.service';
import { InternalService } from 'src/app/internal/services/internal.service';
import { TextInput } from 'src/utils/models/input.interface';
import { SwalComponent } from '../swal/swal.component';
import { ModalLoadingComponent } from '../../loads/modal-loading/modal-loading.component';
const DATE_FORMAT = 'DD/MM/YYYY';

@Component({
  selector: 'app-modal-validate-event',
  templateUrl: './modal-validate-event.component.html',
  styleUrls: ['./modal-validate-event.component.scss']
})
export class ModalValidateEventComponent implements OnInit {

  typeButton = [ 
    {
      type: 'error', name: 'Error' 
    },
    {
      type: 'success', name: 'Success' 
    },
    {
      type: 'alert', name: 'Alert' 
    },
    {
      type: '', name: 'Default' 
    }
  ];
  
  FINISH_COLOR =  { background: "#EDFDF4", content: '#17B55F' }
  WAITING_COLOR = { background: "#E0E9F6", content: "#102956"}
  CANCELED_COLOR = { background: "#FFD9D6", content: "#660700"}  
  NEW_COLOR = { background: "#FDF1D8", content: "#5F4407"}
  GROUP_COLOR = { background: "#E5DAFB", content: "#250A5C"}

  ARRAY_COLORS = {"Agrupada": this.GROUP_COLOR,"Finalizado": this.FINISH_COLOR, "Finalizada": this.FINISH_COLOR, "Nova": this.NEW_COLOR, "Cancelada": this.CANCELED_COLOR, "Em andamento": this.WAITING_COLOR}
  ARRAY_ICONS =  {"Agrupada": 'group',"Finalizada": 'circle_minus', "Nova": 'Notification', "Cancelada": 'canceled', "Em andamento": 'Play Circle'}
  ARRAY_CLASS =  {"Agrupada":"group","Finalizada": 'finished', "Nova": 'new', "Cancelada": 'cancel', "Em andamento": 'progress'}
  
  form = this.formBuilder.group({
    timeValue: new FormControl(''),
    datapickerValue: new FormControl(new Date()),
    description: new FormControl(''),
    breakers: new FormControl(''),
    trips: new FormControl(''),
    pv: new FormControl(''),
    sap: new FormControl(''),
    weather: new FormControl('')
  });

  timerInterface =  { disabled: true, value: this.form.value.timeValue, label: 'Time Disabled', placeholder: '00:00h' }
  datapickerInterface =  { disabled: true, date: this.form.value.datapickerValue, label: 'Datapicker Disabled', placeholder: DATE_FORMAT }
  checkBoxInterface = [
    { disabled: false, label: "Inserir histórico de mensagens no whatsapp", checked:false},
    { disabled: false, label: "Enviar dados da ocorrência por e-mail", checked:false},
  ]
  textAreaInterface = [
    { value: this.form.value.description, label: 'Descrição', rows: 4, placeholder: 'value',  hasEditableIcon: true },
    { value: this.form.value.breakers, label: 'Disjuntores', rows: 4, placeholder: 'value' ,  hasEditableIcon: true},
    { value: this.form.value.trips, label: 'Proteções', rows: 7, placeholder: 'value' ,  hasEditableIcon: true},
    { value: this.form.value.sap, label: 'Intervenções programadas', rows: 5, placeholder: '',disabled: true },
    { value: this.form.value.weather, label: 'Informações climatológicas', rows: 5, placeholder: '',disabled: true },
  ];
  textInterface: TextInput 

  occurrence;

  constructor(
    public dialogRef: MatDialogRef<ModalValidateEventComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    private readonly internalService: InternalService,
    private readonly toastr: ToastrService,
    private readonly eventDispatcherService: EventDispatcherService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.occurrence = this.data.sendData;
    const occurrenceDate = new Date(this.occurrence.date)
    this.form.get('timeValue').setValue(occurrenceDate.toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'}));
    this.form.get('datapickerValue').setValue(occurrenceDate);
    this.form.get('description').setValue(this.occurrence.descriptionText)
    this.form.get('sap').setValue(this.occurrence.sapText)
    this.form.get('breakers').setValue(this.occurrence.breakersText)
    this.form.get('trips').setValue(this.occurrence.tripsText)
    this.form.get('weather').setValue(this.occurrence.weatherText)
    this.form.get('pv').setValue(this.occurrence.pv ? ('R$ ' + this.occurrence.pv.toLocaleString('pt-BR',{
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })) : "Valor não mapeado.")

    this.initValues();
  }

  initValues(){
    this.timerInterface =  { disabled: true, value: this.form.value.timeValue, label: 'Hora', placeholder: '00:00h' }
    this.datapickerInterface =  { disabled: true, date: this.form.value.datapickerValue, label: 'Data', placeholder: DATE_FORMAT }
    this.checkBoxInterface = [
      { disabled: false, label: "Inserir histórico de mensagens no whatsapp", checked:false},
      { disabled: false, label: "Enviar dados da ocorrência por e-mail", checked:false}]

    this.textAreaInterface = [
      { value: this.form.value.description, label: 'Descrição', rows: 4, placeholder: 'Digite a Descrição',  hasEditableIcon: true },
      { value: this.form.value.breakers, label: 'Disjuntores', rows: 4, placeholder: 'Digite os Disjuntores',  hasEditableIcon: true },
      { value: this.form.value.trips, label: 'Proteções', rows: 7, placeholder: 'Digite as Proteções',  hasEditableIcon: true },
      { value: this.form.value.sap, label: 'Intervenções programadas', rows: 5, placeholder: '',disabled: true },
      { value: this.form.value.weather, label: 'Informações climatológicas', rows: 5, placeholder: '',disabled: true },
    ];

    console.log(this.data)

    this.textInterface = {value: this.form.value.pv, placeholder: '', label: 'Parcela Variável (R$/h)', disabled: true}
  }

  confirmReject(){
      this.dialog.open(SwalComponent, {
        disableClose: true,
        width: '352px',
        height: '200px',
        panelClass: 'custom-modalbox-swal',
        //maxHeight: '941px',
        data: {
          type: 'error',
          cancelButton: {text: 'VOLTAR'},
          validationButton: {text: 'CANCELAR'},
          title: 'Cancelar ocorrência',
          text: 'Ao Cancelar uma ocorrência o status dela passará a ser cancelado e ela não será disparada na mensageria e não poderá ser editada, deseja realmente cancelar?'
        }
      }).afterClosed().subscribe(res=>{
        if(res){
          this.handleClick('Rejeitada')

        }
      })
  }

  handleClick(type): void {

    if(!type) this.dialogRef.close(type);

    else{

      const dialog = this.dialog.open(ModalLoadingComponent, {
        width: '400px',
        disableClose: true,
        data: {
          title: 'Carregando',
          text: 'Por favor espere alguns segundos',
        },
      });

      const sendData = { 
        rejected: type === 'Rejeitada',
        sendGroupHistory : this.checkBoxInterface[0].checked, 
        sendEmail: this.checkBoxInterface[1].checked,
        descriptionText : this.form.value.description, 
        tripsText : this.form.value.trips, 
        breakersText: this.form.value.breakers, 
        status: type
      }

      if(!sendData.rejected){
        this.internalService.aproveGroup(this.occurrence.id,sendData).subscribe(res=>{
          this.eventDispatcherService.emitEditNotifyOccurrence(true);
          this.toastr.show('Ocorrência editada com sucesso', 'Sucesso', { timeOut: 500 , messageClass: 'success'});
          this.dialogRef.close(type);
          dialog.close();
        },error=>{
          console.log(error)
          if(error.status == 409){
            this.toastr.show("Esta ocorrência já foi aprovada", 'Erro', { timeOut: 500 , messageClass: 'error'})
          }else{
            this.toastr.show("Tente novamente mais tarde.", 'Erro', { timeOut: 500 , messageClass: 'error'})
          }
          dialog.close();
          this.dialogRef.close(type);
        })
      }else{
        this.internalService.editOccurrences(this.occurrence.id,sendData).subscribe(el=>{
          this.toastr.show('Ocorrência editada com sucesso', 'Sucesso', { timeOut: 500 , messageClass: 'success'});
          
            this.internalService.rejectGroup(this.occurrence.id).subscribe(res=>{
              this.eventDispatcherService.emitEditNotifyOccurrence(true);
              dialog.close();
              this.dialogRef.close(type);
            },error=>{
              console.log(error)
              if(error.status == 409){
                this.toastr.show("Esta ocorrência já foi aprovada", 'Erro', { timeOut: 500 , messageClass: 'error'})
              }else{
                this.toastr.show("Tente novamente mais tarde.", 'Erro', { timeOut: 500 , messageClass: 'error'})
              }
              dialog.close();
              this.dialogRef.close(type);
            })
        })
      }


    }

  }

  onCheckChange(event,index){
    this.checkBoxInterface[index].checked = event;
  }

  getTagColors(tag){
    return {content:tag, icon: this.ARRAY_ICONS[tag], customColor: this.ARRAY_COLORS[tag],class: this.ARRAY_CLASS[tag]}
  }

  onValueEmitterTextAreaInput(newValue,field){

    this.form.get(field).setValue(newValue)

  }

  disabledButtons(){
    return !this.form.value['breakers'] || this.form.value['breakers'] == "" ||
           !this.form.value['description'] && this.form.value['description'] == "" ||
           !this.form.value['trips'] || this.form.value['trips'] == ""

  }

}
